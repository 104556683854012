import React from 'react'
import "./CustomTable.css"
import Button from '../Button/Button'
import { MdDownload, MdFilter, MdFilterAlt } from 'react-icons/md'
import StatusLabel from '../Status/StatusLabel'

function CustomTable({title, json, isDownloadAllowed = true}) {

    const loadTable = (jsonObject) => {
        if(jsonObject.length > 0){
            let tableHeadColumns = [];
            let tableDataRows = [];
            let jsonHeader = Object.keys(jsonObject[0]);
            jsonHeader.forEach((ele, ind) => {
                tableHeadColumns.push(<th key={`table-custom-head-${ind}`}>{ele} <MdFilterAlt size={13} color='rgba(0,0,0,0.5)' /></th>)
            })
            jsonObject.forEach((ele, index) => {
                let tableData = [];
                jsonHeader.forEach((key, i) => {
                    if(key.toLowerCase() != "status")
                        tableData.push(<td key={`table-custom-cell-${i}`}>{ele[key]}</td>)
                    else{
                        tableData.push(<td key={`table-custom-cell-${i}`} ><StatusLabel>{ele[key]}</StatusLabel></td>)
                    }
                })
                tableDataRows.push(<tr key={`table-custom-${index}`}>{tableData}</tr>)
            })
            return (
                <table className='table'>
                    <thead>
                        <tr>{tableHeadColumns}</tr>
                    </thead>
                    <tbody>
                        {tableDataRows}
                    </tbody>
                </table>
            )
        }
        else{
            return (
                <table className='table'>
                    <thead style={{boxShadow : 'none'}}>
                        <tr>
                            <td style={{textAlign : "center"}}>No Data Available</td>
                        </tr>
                    </thead>
                </table>
            )            
        }
    }
    const downloadTable = () => {
        window.print()
    }
  return (
    <div className='mainTableContainer' id="printablediv">
        <div className='tableHeader'>
            <div className='tableTitle'>{title}</div>
            <Button 
                margin={"0px"} 
                primary={false} 
                className='minButton tableButton'
                onPressFunction={downloadTable}
            ><MdDownload size={18}/> Download</Button>
        </div>
        <div className='onlyTableDiv scroller'>
            {loadTable(json)}
        </div>
        
    </div>
  )
}

export default CustomTable