import React, { useEffect } from 'react'
import { MdCheck } from 'react-icons/md'
import "./FloatingInput.css"

function FloatingInput({value=null, label = "", name="", type="", required=false, check=false, handleChange=null}) {
    const focusInput = (e) => {
        if(check){
          e.target.previousSibling.previousSibling.style.fontSize = "10px"
          e.target.previousSibling.previousSibling.style.left = "10px"
          e.target.previousSibling.previousSibling.style.top = "10px"
        }
        else{
          e.target.previousSibling.style.fontSize = "10px"
          e.target.previousSibling.style.left = "10px"
          e.target.previousSibling.style.top = "10px"
        }
    }
    const focusInputFromLabel = (e) => {
      e.target.parentElement.lastChild.focus();
    }
    useEffect( () => {
      if(value != null){
        if(check){
          document.getElementsByName(name).item(0).previousSibling.previousSibling.style.fontSize = "10px"
          document.getElementsByName(name).item(0).previousSibling.previousSibling.style.left = "10px"
          document.getElementsByName(name).item(0).previousSibling.previousSibling.style.top = "10px"
        }
        else{
          document.getElementsByName(name).item(0).previousSibling.style.fontSize = "10px"
          document.getElementsByName(name).item(0).previousSibling.style.left = "10px"
          document.getElementsByName(name).item(0).previousSibling.style.top = "10px"
        }
        
      }
    }, [value])
  return (
    <div className='floatingInput'>
        <div className='floatingLabel' onClick={focusInputFromLabel}>{label}</div>
        {
          check &&
          <span className='floatingCheck'>
              <MdCheck size={14} />
          </span>
        }
        <input value={value} onChange={handleChange} type={type} name={name} onFocus={focusInput} required={required}/>
    </div>
  )
}

export default FloatingInput