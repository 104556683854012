import React, { useEffect, useState } from 'react'
import "./OtpBox.css"
import Button from '../Button/Button';
import { FormException } from '../../utility/exceptions';
import { generateOTP } from '../../services/auth.service';

function OtpBox({length = 6, handleValidation, source, isNew = false, type = "phone"}) {
    
    
    const [diff, setDiff] = useState(1);
    const [resend, toggleResend] = useState(false);

    let messageDiv = document.getElementsByClassName("message").item(0);

    const validator = (e) => {
        e.preventDefault()
        let otp = "";
        const OTP_Container = document.getElementsByClassName("otpContainer")[0];
        for (let i = 0; i < OTP_Container.children.length; i++) {
            otp += OTP_Container.children.item(i).value;
        }

        if(otp.length === 6){
            messageDiv.style.display = "none";
            messageDiv.classList.remove("error");
        }
        else{
            return FormException(e, "Please Enter Valid OTP", messageDiv);
        }
        handleValidation(otp, type, source);
    }

    const handleChange = (e) => {
        var numberRegex = /^\d+$/;
        
        if(!numberRegex.test(e.target.value)){
            if(e.target.value.trim().length === 0){
                e?.target?.previousSibling?.focus();
                return null            
            }
            document.getElementById(e.target.id).value = ""
            return null;
    }

        e?.target?.nextSibling?.focus();
    }
    const getOTP = () => {
        generateOTP(source, isNew, type).then((data) => {
            if(data.status){
                toggleResend(false);
                messageDiv.innerHTML = data.message;
                messageDiv.style.display = "block"
                messageDiv.classList.add("success");
            }
            else{
                return FormException(null, data.message, messageDiv);
            }
        }).catch((error) => {
            return FormException(null, error.message, messageDiv);
        })
    } 

    const startTimer = (expiry) => {

        const timeLeftForResend = expiry;

        let interval = setInterval(() => {
            const currentTime = new Date();
            let temp = (timeLeftForResend.getTime() - currentTime.getTime());
            setDiff(temp)
            if(temp / 1000 <= 1){
                clearInterval(interval); 
                toggleResend(true);
            }   
        },1000)

    }

    useEffect(() =>{
        startTimer(new Date(new Date().getTime() + 1000 * process.env.REACT_APP_OTP_EXPIRY))
    }, [])


    let digit = [];
    for (let index = 0; index < length; index++) {
        digit.push(
            <input type="text" onKeyUp={handleChange} key={index} maxLength="1" id={"OTP_DIGIT_" + (index + 1)}/>
        )
    }

    return (
        <div className='otp'>
            <form className='otpForm' onSubmit={validator}>
                <div className='otpContainer'>
                    {digit}
                </div>
                <div className='message'>sample text</div>
                <Button primary={true}>Verify</Button>
            </form>
            <div className="otpFooter">
                <label>Having Issues?&nbsp;&nbsp;</label>
                <span className={!resend ? 'inActiveOTPButton' : 'activeOTPButton'} onClick={resend ? getOTP : null}>Resend Code (
                    {
                        Math.floor(( diff % (1000 * 60 * 60)) / (1000 * 60))
                    }
                    :
                    {
                        Math.floor((diff % (1000 * 60)) / 1000)
                    }
                )</span>
            </div>
        </div>

    )
}

export default OtpBox