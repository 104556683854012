import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { cacheUser, isLoggedIn } from '../../utility/util';
import { fetchUserDetails } from '../../services/account.service';
import Header from '../../components/Header/Header';
import SideNavBar from '../../components/SideNavBar/SideNavBar';
import ManageProperty from '../../components/property/ManageProperty';
import AddProperty from '../../components/property_manager/add/AddProperty';
import EditProperty from '../../components/property_manager/edit/EditProperty';

function Property({page="ManageProperty"}) {
  const navigate = useNavigate();

  const searchParams = useParams();

  const [userData, setUserData] = useState({});
  const [pageSelect, setPageSelect] = useState()
  const [editPropertyJson, setEditPropertyJson] = useState(null);

  const editProperty = (propertyJson) => {
    setEditPropertyJson(propertyJson);
    setPageSelect("EditProperty");
  }

  useEffect(() => {
    document.title = "Property Manager | Properties | Ando";
      let p = searchParams?.action == "add" ? "AddProperty" : page;

      setPageSelect(p);
      const userInfo = isLoggedIn();
      if(!userInfo){
          return navigate("/login");
      }
      else{
          if(!userInfo?.data){
              fetchUserDetails(userInfo.source, userInfo.type).then((resp) => {
                  setUserData({
                      data : resp.data,
                      source : userInfo.source,
                      type : userInfo.type
                  });
                  cacheUser(resp.data)
                  if(resp?.data?.setup_completed !== "Completed"){
                      return navigate("/account/setup")
                  }
              });
          }
          else{
              setUserData(userInfo);
              if(userInfo?.data?.setup_completed !== "Completed"){
                  return navigate("/account/setup")
              }
          }
      }

  }, [navigate]);
  
  return (
    <main style={{height : '100%', display : 'flex', flexDirection : 'column'}}>
      {
        userData && userData?.data &&
        <Header showNotification={true}  name={userData?.data?.first_name + " " + userData?.data?.last_name} />
      }
      <section className="mainPanel" style={{flex : 1, gap : '30px', paddingTop : 0, display : "flex", width : '100%', maxWidth : 2500}} >

        <SideNavBar userData={userData} />

        <div className="DashContainer">
          <div className='dashHeader'>
            <div className="pageTitle">Property Details</div>
          </div>
          {
            pageSelect == "ManageProperty" && userData && userData?.data &&
            <ManageProperty 
              editFunction={editProperty}
              userData={userData}
              onAddNew={() => {setPageSelect("AddProperty")}}
            />
          }
          {
            pageSelect == "AddProperty" && userData && userData?.data &&
            <AddProperty 
              userData={userData} 
              pageChange={setPageSelect}
            />
          }
          {
            pageSelect == "EditProperty" && userData && userData?.data &&
            <EditProperty 
              userData={userData} 
              pageChange={setPageSelect}
              propertyJson={editPropertyJson}
            />
          }
        </div>
      </section>
    </main>
  );
}

export default Property