import React, { useState } from 'react'
import ToggleButton from '../../../ToggleButton/ToggleButton';

function PropertyManagerSetup({listName, validateInput, defaultClose=false}) {

  const [renterOccupied, setRenterOccupied] = useState(false);
  const [partialPayments, setPartialPayments] = useState(false);
  const [penalty, setPenalty] = useState(false);

  return (
    <div className={'propertyDiv ' + listName} style={{display : defaultClose?"none":"block"}}>
        <div className='columnFormInput'>
            <div className='formInput'>
                <div>Property Name</div>
                <input type='text' name={"propertyName" }  onChange={validateInput} required/>
            </div>
            {/* <div className='formInput'>
                <div div-width="toggle">Renter Occupied?</div>
                <ToggleButton name={"renterOccupied" } selected={renterOccupied} toggleFunction={() => setRenterOccupied(!renterOccupied)} />
            </div> */}
            {
                renterOccupied &&
                <div className='formInput' style={{marginLeft: 50}}>
                    <div>Lease Duration</div>
                    <input type='date' data-div="min-width" name={"leaseFrom" } onChange={validateInput} required/>
                    <input type='date' data-div="min-width" name={"leaseTo" } onChange={validateInput} required/>
                </div>
            }
            {
                !renterOccupied &&
                <>
                    <input type='hidden' data-div="min-width" name={"leaseFrom" }/>
                    <input type='hidden' data-div="min-width" name={"leaseTo" }/>
                </>
            }
            <div className='formInput'>
                <div>Rental Price</div>
                <input type='text' data-div="min-width" name={"rentalPrice" } onChange={validateInput} required/>
            </div>
            {/* <div className='formInput'>
                <div div-width="toggle">Allow partial payments?</div>
                <ToggleButton name={"partialPayment" } selected={partialPayments} toggleFunction={() => setPartialPayments(!partialPayments)} />
            </div> */}
            <div className='formInput'>
                <div>Penalty for late payments?</div>
                <ToggleButton name={"penalty" } selected={penalty} toggleFunction={() => setPenalty(!penalty)} />
            </div>
            {
                penalty &&
                <div className='formInput'  style={{marginLeft: 50}}>
                    <div div-width="toggle">Penatly Price</div>
                    <input type='text' data-div="min-width" name={"penaltyPrice" } onChange={validateInput} required/>
                </div>
            }
            {
                !penalty &&
                <input type='hidden' data-div="min-width" name={"penaltyPrice" }/>
            }
            </div>
            <br />
            <br />
            <div className="currentAccountActionTitle">Enter Renter's Details (Optional)</div>
            <div className="currentAccountActionSubTitle">If filled in, we'll auto verify</div>
            <br />
            <div className='columnFormInput'>
                <div className='rowFormInput'>
                    <div className='formInput'>
                        <div>First Name</div>
                        <input type='text' name={"first_name" } onChange={validateInput} />
                    </div>
                    <div className='formInput'>
                        <div style={{justifyContent : 'center'}}>Last Name</div>
                        <input type='text' name={"last_name" }  onChange={validateInput} />
                    </div>
                </div>
                <div className='columnFormInput'>
                    <div className='formInput'>
                        <div>Email Address</div>
                        <input type='text' name={"email" }  onChange={validateInput} />
                    </div>
                    <div className='formInput'>
                        <div>Mobile Number</div>
                        <input type='text' name={"phone" }  onChange={validateInput} />
                    </div>
                </div>
            </div>
        </div>
  )
}

export default PropertyManagerSetup