import React, { useEffect, useState } from "react";
import "./Header.css";

import { MdNotifications } from "react-icons/md";
import { useNavigate } from "react-router-dom";
function Header({ showNotification = false, name = "" }) {
  const [abbrName, setAbbrName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if(name.length > 0){
      let d = name.split(" ");
      setAbbrName(d[0].charAt(0) + "" + d[1].charAt(0))
    }
  }, []);

  const logoClicked = () => {
    navigate("/dashboard")
  }

  return (
    <section className="header">
      <div className="header-row">
        <div className="logo" style={{cursor:'pointer'}} onClick={logoClicked}>ando</div>

        {showNotification && (
          <div className="topbar">
            <a href="#notifCount" className="icon-link">
              <span>
                {" "}
                <MdNotifications color="#A5A5A5" size={30} />  </span>
                <span className="icon-link__badge">12</span>
            </a>
            <div className="ellipse">{abbrName}</div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Header;
