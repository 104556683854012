import React, { useEffect, useState } from 'react'
import "./Auth.css"
import Header from '../../components/Header/Header';
import Wrapper from '../../components/wrapper/Wrapper';
import {MdClose, MdVisibility} from  "react-icons/md"
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { FormException } from '../../utility/exceptions';
import { isLoggedIn, performLogin } from '../../utility/util';
import { resetPasswordAPI } from '../../services/auth.service';

function ResetPassword() {
  const navigate = useNavigate();
  const searchParams = useParams();
  const [linkExpired, setLinkExpired] = useState(false);
  const [email, setEmail] = useState("");
  
  useEffect(() => {
    document.title = "Forgot Password | ando"
    if(isLoggedIn()){
      navigate("/")
    }
  }, [navigate])

  useEffect(() => {
    let data = atob(atob(searchParams?.hash)).split("-");
    let currentDate = new Date();
    let linkDate = new Date(data[2]);
    let diffDate = currentDate - linkDate;
    let totalMinutes = Math.floor(diffDate / 1000 / 60);
    if(totalMinutes > 15){
      setLinkExpired(true);
    }
    else{
      setEmail(data[0])
    }
  }, []);

  const toggleInputMask = (inputId) => {
    let inputBox = document.getElementById(inputId);
    switch(inputBox.type){
      case "password" : 
        inputBox.type = "text";
      break;
      case "text" :
        inputBox.type = "password";
      break;
    }
  } 

  const resetPassword = (e) => {
    e.preventDefault();
    let messageDiv = document.getElementsByClassName("message").item(0);
    let password = e.target.password.value;
    let confirm_password = e.target.cnfpassword.value;

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/

    if(!passwordRegex.test(password)){
      return FormException(
          e,
          `Password criteria is not fullfilled <br>
          Password length should be more than 8 letters <br>
          Password must contain uppercase, lowercase and a number. <br>`, 
          messageDiv
        )
    }

    if(confirm_password !== password){
      return FormException(
        e,
        "Password & Confirm Password are not same.",
        messageDiv
      )
    }
    resetPasswordAPI(email, password)
    .then((resp) => {
      if(resp?.status){
        messageDiv.style.display = "none";
        messageDiv.classList.remove("error");
        navigate("/login")
      }
      else{
        return FormException(null, resp.message, messageDiv);
      }
    })

  }
  return (
    <main>
      <Header />
      <section className='mainPanel'>
        {
          !linkExpired &&
          <div className={"fadeIn"}>
            <Wrapper width='500px'>
              <Link to="/login">
                <MdClose color='rgba(0,0,0,0.35)' size={20} className='closePopup'/>
              </Link>
              <div className='signInTitleWrapper'>
                <label style={{fontSize : 35}}>Create New Password</label>
                <p style={{fontSize : 15}}>Create a unique password with at least eight characters. Password must contain uppercase, lowercase and a number.</p>
              </div>
    
              <form className='signInForm' onSubmit={resetPassword}>
                <div className='signInInput'>
                  <label>New Password</label>
                  <input type='password' name='password' id="password" placeholder='Password' required/>
                  <MdVisibility 
                    className='maskInputContainer'
                    onClick={toggleInputMask.bind(this, "password")}
                    color='rgba(0,0,0,0.25)' 
                    size={18}/>
                </div>
                <div className='signInInput'>
                  <label>Confirm New Password</label>
                  <input type='password' name='cnfpassword' id="cnfpassword" placeholder='Password' required/>
                  <MdVisibility 
                    className='maskInputContainer'
                    onClick={toggleInputMask.bind(this, "cnfpassword")}
                    color='rgba(0,0,0,0.25)' 
                    size={18}/>
                </div>
                <Button>Save Password & Sign In</Button>
                  <div className='message' style={{display : 'block'}}>
                  <b>Please follow the below password criteria</b><br />
                  Password length should be more than 8 letters <br />
                  Password must contain uppercase, lowercase and a number. <br />
                  </div>
              </form>
    
              <div className='hr'></div>
    
              <p className='tncMessage' onClick={() => {window.open("/tnc", "_blank", 'rel=noopener noreferrer')}}>By Signing In to ando, you agree to our Privacy Policies & Terms of Services</p>
    
              <div className="signLinkContainer">New User? <Link to={"/register"} className='loginLinks'>Sign Up Here</Link> </div>
            </Wrapper>
          </div>
        }
        {
          linkExpired &&
          <div style={
            {
                display:'flex',
                flexDirection : 'column', 
                height : '80vh', 
                justifyContent : 'center', 
                alignItems : 'center'
            }
          }>
            <h5>Link Expired <Link to={"/login"}>Click Hear</Link> to go back</h5>
            <p>Copyright by - Ando</p>
          </div>
        }
      </section>
    </main>
  )
}

export default ResetPassword