import React, { useContext, useEffect, useState } from 'react'
import "./ManageAccount.css"
import CustomWrapper from '../../wrapper/CustomWrapper';
import Button from '../../Button/Button';
import { MdAdd, MdClose } from 'react-icons/md';
import { getPaymentMethodForUser } from '../../../services/payment.method.service';
import { fetchAccounts } from './fetchAccounts';
import { AppContext } from '../../../App';
import { Loader } from '../../Loader/Loader';

function ManageAccount({userData = null, editFunction=null, onAddNew, goBack}) {
    const [accountList, setAccountList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
      document.title = "Manage Payment Accounts | Ando";
      setLoading(true)
      fetchAccounts(userData?.data?.id, editFunction)
      .then((accounts) => {
        setLoading(false)
        setAccountList([...accounts]);
      })
    }, []);

    return (
        <CustomWrapper
          width='100%'
          maxWidth={2000}
          height='600px'
          maxHeight={"100%"}
          paddingHorizontal={30}
          paddingVertical={30}
        >
        <MdClose
          onClick={goBack}
          color="rgba(0,0,0,0.65)"
          size={20}
          className="closePopup"
        />
          <div className='paymentOptionContainer' style={{height : '100%'}}>
            <div className='manageAccountHeader'>
                <div className='title'>Manage Accounts</div>
                <Button
                    className='minButton'
                    onPressFunction={onAddNew}
                >
                    <MdAdd color='#FFFFFF' size={16}/>
                    Add Account
                </Button>
            </div>
            <div className='accountsContainerC scroller'>
                {accountList}
            </div>
          </div>
          {
            isLoading && <Loader />
          }
        </CustomWrapper>
    )
}


export const Account = ({name, last4, isPrimary, id, editFunction, data, payment_type}) => {
    return (
        <div className='account'>
            <div className='leftDiv'>
                <div className='accountTitle'><span>{name}</span><p>{payment_type}</p></div>
                <div className='endingWith'>Ending in ******{last4}</div>
            </div>
            <div className='rightDiv'>
                {
                    isPrimary &&
                    <div className='primaryTag'>Primary Account</div>
                }
                {
                    !isPrimary &&
                    <div className='primaryTag' style={{visibility : 'hidden'}}>Primary Account</div>
                }
                {
                    (editFunction != null || editFunction != undefined) &&
                    (data != null || data != undefined) &&
                    <div className='editLink' onClick={() => {editFunction(data)}}>Edit</div>
                }                
            </div>
        </div>
    )
}

export default ManageAccount