import React, { useEffect, useState } from "react";
import "./NotificationBar.css";
import Wrapper from "../wrapper/Wrapper";
import Notice from "./Notice";
import { fetchNotificationByUser } from "../../services/account.service";
import { MiniLoader } from "../Loader/Loader";

export default function NotificationBar({userData=null}) {
  const [noticeList, setNoticeList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(()=>{
    let list = [];
    setLoading(true)
    fetchNotificationByUser(userData?.data?.id)
    .then((resp) => {
      setLoading(false)
      if(resp.status){
        resp.data.forEach((element) => {
          list.push(<Notice
            currentReadStatus={element.isRead}
            title={element.title}
            description={element.description}
            id={element.id}
            date={new Date(element?.created_at.split("T")[0])}
            key={element.id}
          />)
        });
        setNoticeList([...list]);
      }
    })
  }, [])
  return (
    <Wrapper width={350} height="100%">
      <div className="notificationBox">
      <div className="title">Recent Activities</div>
      <div className="noticeContainer scroller">
        {
          isLoading && 
          <div style={{width : '100%', textAlign : 'center'}}>
            <MiniLoader />
          </div>
        }
        {!isLoading && noticeList}
      </div>
      </div>
    </Wrapper>
  );
}
