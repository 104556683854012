import React, { useEffect, useState } from 'react'
import "./Graph.css"

function Graph({json, graphDuration}) {

    const [graphTitle, setGraphTitle] = useState("");
    const [graphSubHead, setGraphSubHead] = useState("");

    useEffect(() => {
        setGraphTitle(json.dataName);
        let len = (graphDuration == 'current') ? 1 : (graphDuration > json.data.length) ? json.data.length : graphDuration;
        let newTotal = 0;
        for(let c=0; c < len; c++){
            newTotal += parseInt(json.data[c].paymentReceived)
        }
        setGraphSubHead("$" + newTotal);
    }, [json, graphDuration])
    
    const customSort = (element1, element2) => {
        let date1 = new Date(element1.month + "-" + element1.year).getTime();
        let date2 = new Date(element2.month + "-" + element2.year).getTime();
        if(date1 < date2)
            return 1;
        else if(date1 > date2)
            return -1
        else
            return 0
    }
    const loadGraph = (data) => {
        let graphData = [];
        // let max = data.max_monthly_payment_expected;
        let len = (graphDuration == 'current') ? 1 : (graphDuration > data.data.length) ? data.data.length : graphDuration;
        data.data = data.data.sort(customSort)
        for(let c=0 ; c < len; c++){
            
            let max = data.data[c].expectedRent;
            let height = Math.round(( data.data[c].paymentReceived / max) * 100 )            
            height = isNaN(height) ? 10 : height < 10 && height >= 0 ? 10 : height == 0 ? 0 : height
            
            if(len > 6){
                graphData.push(
                    <div className='barBox' key={`graph-index-${c}`}>
                        <span>{"$" + data.data[c].paymentReceived}</span>
                        <div className='barMin'>
                            <div className='barFillMin' style={{height : `${height}%`}}>
                            </div>
                        </div>
                        <div className='xAxisLabelMin'>{data.data[c].month + ", " + data.data[c].year}</div>
                        <div className='hoverGraphBarDataToolTip'>
                            <div className='topData'>
                                <div className='barDate'>{data.data[c].month.substring(0, 3) + ", " + data.data[c].year}</div>
                                <div className='percent'>{max > 0 ? Math.round( ( data.data[c].paymentReceived / max ) * 100 ) : 0}%</div>
                            </div>
                            <div className='bottomData'>
                                <div className='incomingAmount'>${data.data[c].paymentReceived}</div>of
                                <div className='totalAmount'>${max}</div>
                            </div>
                        </div>
                    </div>
                )
            }
            else{
                graphData.push(
                    <div className='barBox' key={`graph-index-${c}`}>
                        <div className='bar'>
                            <div className='barFill' style={{height : `${height}%`}}>
                                <span>{"$" + data.data[c].paymentReceived}</span>
                            </div>
                        </div>
                        <div className='xAxisLabel'>{data.data[c].month + ", " + data.data[c].year}</div>
                        <div className='hoverGraphBarDataToolTip'>
                            <div className='topData'>
                                <div className='barDate'>{data.data[c].month.substring(0, 3) + ", " + data.data[c].year}</div>
                                <div className='percent'>{max > 0 ? Math.round( ( data.data[c].paymentReceived / max ) * 100 ) : 0}%</div>
                            </div>
                            <div className='bottomData'>
                                <div className='incomingAmount'>${data.data[c].paymentReceived}</div>of
                                <div className='totalAmount'>${max}</div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return graphData.reverse();
    }

    return (
        <div className='graphContainer'>
            <div className='graphHeader'>
                <div className='graphTitle'>{graphTitle}</div>
                <div className='graphAmount'>{graphSubHead}</div>
            </div>
            <div className='graph'>
                <div className='yAxisLabel'>
                    <div>100%</div>
                    <div>75%</div>
                    <div>50%</div>
                    <div>25%</div>
                    <div>0</div>
                </div>
                <div className='graphBox'>
                    <div className='graphBackGround'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className='graphForeGround'>
                        {loadGraph(json)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Graph

