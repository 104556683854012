import React, { useEffect, useState } from 'react'
import RenterDashboard from './renter/Dashboard'
import PropertyManagerDashboard from './property_manager/Dashboard'
import { cacheUser, isLoggedIn } from '../../utility/util'
import { useNavigate, useParams } from 'react-router-dom';
import { fetchUserDetails } from '../../services/account.service';

function Dashboard() {
    const navigate = useNavigate();
    const searchParams = useParams();
    const [userData, setUserData] = useState({});    
    

    useEffect(() => {
        const userInfo = isLoggedIn();
        if(!userInfo){
            return navigate("/login");
        }
        else{
            if(!userInfo?.data){
                fetchUserDetails(userInfo.source, userInfo.type).then((resp) => {
                    setUserData({
                        data : resp.data,
                        source : userInfo.source,
                        type : userInfo.type
                    });
                    cacheUser(resp.data)
                    if(resp?.data?.setup_completed != "Completed"){
                        return navigate("/account/setup")
                    }
                });
            }
            else{
                setUserData(userInfo);
                if(userInfo?.data?.setup_completed != "Completed"){
                    return navigate("/account/setup")
                }
            }
        }

    }, [navigate]);


    return (
        <>
            {
                userData && (userData?.data?.role.user_role.replace(" ", "_") === searchParams.identifier === "Renter" || userData?.data?.role.user_role.replace(" ", "_") === "Renter") &&
                <RenterDashboard userData={userData}/>

            }
            {
                userData && (userData?.data?.role.user_role.replace(" ", "_") === searchParams.identifier === "Property_Manager" || userData?.data?.role.user_role.replace(" ", "_") === "Property_Manager") &&
                <PropertyManagerDashboard userData={userData}/>
            }
        </>
    )

}

export default Dashboard