import React, { useContext, useEffect, useState } from 'react'
import "../Payment.css"
import Header from '../../../components/Header/Header'
import SideNavBar from '../../../components/SideNavBar/SideNavBar'
import Button from '../../../components/Button/Button'
import PaymentMethod from '../../../components/payment/property_manager/PaymentMethod'
import ManageAccount from '../../../components/payment/common/ManageAccount'
import PaymentDashboard from '../../../components/payment/property_manager/PaymentDashboard'
import { AppContext } from '../../../App'

function PropertyManagerPayment({userData = null, initialComponent = null}) {
  
  const [pmPaymentComponent, setPmPaymentComponent] = useState("PaymentDashboard");
  const [editAccountData, setEditAccountData] = useState(null);
  const [graphDuration, setGraphDurationIndex] = useState(6);

  const editFunction = (data) => {
    setEditAccountData(data);
    setPmPaymentComponent("EditAccount")
  }
  
  useEffect(() => {
    if(initialComponent != null && pmPaymentComponent != initialComponent){
      setPmPaymentComponent(initialComponent);
    }
  }, []);

  const setGraphDuration = (duration) => {
    setTimeout(() => {
      setGraphDurationIndex(duration)
    }, 500)
  }

  return (
    <main style={{height : '100%', display : 'flex', flexDirection : 'column'}}>
        <Header showNotification={true}  name={userData?.data?.first_name + " " + userData?.data?.last_name} />
        <section className="mainPanel" style={{flex : 1, gap : '30px', paddingTop : 0, display : "flex", width : '100%', maxWidth : 2500}} >
            <SideNavBar userData={userData}/>
            <div className="DashContainer">
              <div className='dashHeader' style={{ alignItems :"center"}}>
                <div className="pageTitle" style={{flex : 1}}>Payment Details</div>

                {
                  pmPaymentComponent === "PaymentDashboard" &&
                  <div className='graphDurationSelectorDiv'>
                    <div 
                      onClick={() => {setGraphDuration('current')}} 
                      className={graphDuration == "current" ? "active" : "" }
                    >Current</div>

                    <div 
                      onClick={() => {setGraphDuration(3)}} 
                      className={graphDuration == 3 ? "active" : "" }
                    >3m</div>

                    <div 
                      onClick={() => {setGraphDuration(6)}} 
                      className={graphDuration == 6 ? "active" : "" }
                    >6m</div>

                    <div 
                      onClick={() => {setGraphDuration(9)}} 
                      className={graphDuration == 9 ? "active" : "" }
                    >9m</div>

                    <div 
                      onClick={() => {setGraphDuration(12)}} 
                      className={graphDuration == 12 ? "active" : "" }
                    >12m</div>
                  </div>
                }
                {
                  pmPaymentComponent === "PaymentDashboard" &&
                  <Button 
                    className='minButton smallBtn'
                    primary={true}
                    onPressFunction={() => {setPmPaymentComponent("ManageAccount")}}
                  >
                    Manage Accounts
                  </Button>
                }
              </div>
                {
                  pmPaymentComponent === "PaymentMethod" &&
                  <PaymentMethod
                    onSuccess={() => {setPmPaymentComponent("PaymentDashboard")}}
                    onCancel={() => {return null}} 
                    isNew={true} 
                    edit={false}
                    userData={userData} 
                    title='Link an account to start collecting rent'/>
                }
                {
                  pmPaymentComponent === "PaymentDashboard" &&
                  <PaymentDashboard 
                    userData={userData} 
                    graphDuration={graphDuration}
                    onNoPaymentAccountFound={() =>{setPmPaymentComponent("PaymentMethod")}}
                  />
                }
                {
                  pmPaymentComponent === "ManageAccount" &&
                  <ManageAccount 
                    goBack={() => {setPmPaymentComponent("PaymentDashboard")}}
                    onAddNew={() => {setPmPaymentComponent("AddNewAccount")}}
                    userData={userData} 
                    editFunction={editFunction}/>
                }
                {
                  pmPaymentComponent === "EditAccount" &&
                  <PaymentMethod 
                    onCancel={() => {setPmPaymentComponent("ManageAccount")}} 
                    isNew={false} 
                    edit={true} 
                    title='Edit account'
                    userData={userData} 
                    data={editAccountData}/>
                }
                {
                  pmPaymentComponent === "AddNewAccount" &&
                  <PaymentMethod 
                    onCancel={() => {setPmPaymentComponent("ManageAccount")}}  
                    isNew={true} 
                    edit={true} 
                    userData={userData} 
                    title='Link an additional account' />
                }
              </div>
        </section>
    </main>
  )
}

export default PropertyManagerPayment