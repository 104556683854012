import React, { useContext, useEffect, useState } from 'react'
import CustomWrapper from '../../wrapper/CustomWrapper'
import FloatingInput from '../../form_element/FloatingInput/FloatingInput'
import Button from '../../Button/Button';
import ErrorPng from "../../../assets/error.png";
import { MdClose, MdDelete } from 'react-icons/md';
import PopupWrapper from '../../wrapper/PopupWrapper';
import {FormException} from "../../../utility/exceptions"
import { addPaymentMethod, deletePaymentMethod, updatePaymentMethod } from '../../../services/payment.method.service';
import { AppContext } from '../../../App';
import { Loader } from '../../Loader/Loader';

function PaymentMethod({ onSuccess=null, userData = null, title = "", isNew=true, data, edit=false, onCancel}) {
  const [isDeleteCalled, setIsDeleteCalled] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [accountData, setAccountData] = useState(null);

  const [routingNumberCheck, setRoutinNumberCheck] = useState(false);
  const [accountNumberCheck, setAccountNumberCheck] = useState(false);
  const [confirmAccountNumberCheck, setConfirmAccountNumberCheck] = useState(false);
  const [accountNicknameCheck, setAccountNicknameCheck] = useState(false);

  useEffect(() => {
    document.title = "Select Payment Method | Property Manager | Ando";
    setAccountData(data);
  }, []);

  const cancleCalled = () => {
    onCancel()
  }

  const deleteAccountConfirm = (called) => {
    setIsDeleteCalled(called);
  }

  const handleInputChange = (e) => {
    let curr = false;
    if(e.target.value.length > 5){
      curr = true;
    }
    let newData = {
      detail : {
        routingNumber : "",
        accountNickname : "",
        accountNumber : ""
      },
      isPrimary : null
    };
    if(accountData != null){
      newData = Object.create(accountData);
    }
    switch(e.target.name){
      case "routingNumber" :
        setRoutinNumberCheck(curr);
        if(!isNew)
          newData.detail.routingNumber = e.target.value
      break;
      case "accountNumber" :
        setAccountNumberCheck(curr);
        if(!isNew) 
          newData.detail.accountNumber = e.target.value
      break;
      case "confirmAccountNumber" :
        setConfirmAccountNumberCheck(curr);
      break;
      case "accountNickname" :
        setAccountNicknameCheck(curr);
        if(!isNew)  
          newData.detail.accountNickname = e.target.value
      break;
      case "isPrimary" : 
        if(!isNew)
          newData.isPrimary = e.target.checked
      break;
    }
    setAccountData(null);
    setAccountData(newData)
  }

  const handlePaymentMethodSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let messageDiv = document.getElementsByClassName("message").item(0);

    if(e.target.confirmAccountNumber.value != e.target.accountNumber.value){
      setLoading(false);
      return FormException(e, "Account Number not maching to confirm account field", messageDiv);
    }

    let dataToSend = {
      detail : {
        accountNumber : e.target.accountNumber.value,
        routingNumber : e.target.routingNumber.value,
        accountNickname : e.target.accountNickname.value,
      },
      isPrimary : e.target?.isPrimary?.checked ?? true,
      payment_type : "account",
      token_type : e.target.accountNumber.value,
      status : "Active",
      user_id : userData?.data?.id,
      isForRenter : false
    }

    if(!isNew && edit){
      updatePaymentMethod(accountData?.id, dataToSend)
      .then((resp) => {
        setLoading(false)
        if(resp.status){
          onCancel()
        }
        else{
          return FormException(null, resp.message, messageDiv);
        }
      })
    }
    else{
      addPaymentMethod(dataToSend)
      .then((resp) => {
        setLoading(false)
        if(resp.status){
          if(onCancel() === null){
            onSuccess();
          }
        }
        else{
          return FormException(null, resp.message, messageDiv);
        }
      })
    }
    messageDiv.style.display = "none"
  }

  const deleteAccountMethod = () => {
    if(accountData?.isPrimary){
      setTimeout(() => {
        deleteAccountConfirm(false)
        setError(true);
      }, 500)
    }
    else{
      setLoading(true)
      let messageDiv = document.getElementsByClassName("message").item(0);
      deletePaymentMethod(accountData?.id)
      .then((resp) => {
        setLoading(false)
        if(resp.status){
          onCancel()
        }
        else{
          return FormException(null, resp.message, messageDiv);
        }
      })
    }
  }

  return (
    <CustomWrapper
      width='100%'
      maxWidth={'auto'}
      height='600px'
      maxHeight={"100%"}
      paddingHorizontal={30}
      paddingVertical={30}
    >
      <form className='paymentOptionContainer' onSubmit={handlePaymentMethodSubmit}>
        <div className='title'>{title}</div>
        <div className='columnFormInput'>
          <FloatingInput 
            value={isNew ? null : accountData?.detail?.routingNumber}
            name='routingNumber'
            label='Routing Number'
            type='text'
            required={true}
            check={routingNumberCheck}
            handleChange={handleInputChange}/>
          <FloatingInput 
            value={isNew ? null : accountData?.detail?.accountNumber}
            name='accountNumber' 
            label='Account Number' 
            type='text' 
            required={true}
            check={accountNumberCheck}
            handleChange={handleInputChange}/>
          <FloatingInput
            name='confirmAccountNumber' 
            label='Confirm Account Number' 
            type='text' 
            required={true}
            check={confirmAccountNumberCheck}
            handleChange={handleInputChange}/>
          <FloatingInput 
            value={isNew ? null : accountData?.detail?.accountNickname}
            name='accountNickname' 
            label='Account Nickname' 
            type='text' 
            required={true}
            check={accountNicknameCheck}
            handleChange={handleInputChange}/>
        </div>
        <div className='rowFormInput'>
          {
            ((!isNew && edit) || (isNew && edit)) &&
            <label className='accountMinButton'>
              <input type='checkbox' name='isPrimary' onChange={handleInputChange} checked={accountData?.isPrimary}/>
              <span>Set as primary acccount</span>
            </label>
          }
          {
            (!isNew && edit) &&
            <label className='accountMinButton' onClick={() => deleteAccountConfirm(true)}>
              <MdDelete color='red' size={15}/>
              <label style={{color : 'red'}}>Delete Account</label>
            </label>
          }
        </div>
        <div className='message'>Sample Text</div>
        <div style={{width : 'fit-content', display : 'flex'}}>
          <Button
            className='minButton'
            type='button'
            primary={false}
            onPressFunction={cancleCalled}
          >Cancel</Button>
          <Button
            className='minButton'
            type='submit'
            primary={true}
          >Save</Button>
        </div>
      </form>
      {
        isDeleteCalled &&
        <PopupWrapper maxWidth={450} onClose={() => {deleteAccountConfirm(false)}}>
          <div style={{fontSize : 16, textAlign : 'center'}}>
            Are you sure you want to delete this account?
          </div>
          <div style={{width : 'fit-content', display : 'flex'}}>
            <Button
              className='minButton'
              type='button'
              primary={false}
              onPressFunction={() => {deleteAccountConfirm(false)}}
            >Cancel</Button>
            <Button
              className='minButton'
              type='submit'
              primary={true}
              onPressFunction={deleteAccountMethod}
            >Delete</Button>
          </div>
        </PopupWrapper>
      }
      {
        error && <PopupWrapper maxWidth={500} onClose={() => {setError(false)}}>
          <div style={{fontSize : 16, textAlign : 'left', display : 'flex', flexDirection : 'row', alignItems : 'center', gap : 10}}>
            <img src={ErrorPng} height={70}/>
            Looks like this is your primary account to receive payments, please add other primary account before you delete this.
          </div>
        </PopupWrapper>
      }
      {isLoading && <Loader />}
    </CustomWrapper>
  )
}

export default PaymentMethod