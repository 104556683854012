import React from 'react'
import { MdCheck, MdDoneAll, MdSchedule } from 'react-icons/md'
import "./StatusLabel.css"
function StatusLabel({children}) {
    let classSpecify = null;
    switch(children.toLowerCase()){
        case "on time" :
            classSpecify = "on-time";
        break;
        case "late" :
            classSpecify = "late";
        break;
        case "partial" : 
            classSpecify = "partial";
        break;
    }
    return (
        <div className={'statusLabel ' + classSpecify}>
            {
                classSpecify == "on-time" &&
                <MdCheck  size={15} color='#FFF'/>
            }
            {
                classSpecify == "late" &&
                <MdSchedule  size={15} color='#FFF'/>
            }
            {
                classSpecify == "partial" &&
                <MdDoneAll  size={15} color='#FFF'/>
            }
            &nbsp;{children}
        </div>
    )
}

export default StatusLabel