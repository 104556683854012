import React from 'react'
import "./Button.css"
function HighlighterButton({className = "", width="auto", fontSize = "18px", margin = "20px", paddingVertical = "15px", paddingHorizontal="0", type="submit", children, onPressFunction = () => {}}) {
  return (
    <button style={
      {
        width: width, 
        fontSize : fontSize, 
        margin : margin, 
        padding : `${paddingVertical} ${paddingHorizontal}` 
      }
    } className={'signInButton highlighted ' + className} onClick={onPressFunction} type={type}>
        {children}
    </button>
  )
}

export default HighlighterButton