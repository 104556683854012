import React, { useContext, useState } from 'react'
import PropertyManagerSetup from '../../account/property_manager/setup/PropertyManagerSetup';
import { MdArrowRight, MdClose, MdOutlineEdit, MdSearch } from 'react-icons/md';
import { FormException } from '../../../utility/exceptions';
import { isAddressAvailable } from '../../../services/property.service';
import { addressSearch, isLoggedIn } from '../../../utility/util';
import HighlighterButton from '../../Button/HighlighterButton';
import Button from '../../Button/Button';
import CustomWrapper from '../../wrapper/CustomWrapper';
import { createNewProperty } from '../../../services/property.service';
import { AppContext } from '../../../App';
import { Loader } from '../../Loader/Loader';

function AddProperty({userData=null, pageChange}) {
    const [isLoading, setLoading] = useState(false);
    const [propertySetupType, setPropertySetupType] = useState("singleUnit");
    const [propertyDetailsList, setPropertyDetailsList] = useState([]);

    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedAddressPlaceID, setSelectedAddressPlaceID] = useState(null);

    const addPropertyUnit = () => {
        let currentPropertyList = propertyDetailsList;
        currentPropertyList.push(<PropertyManagerSetup listName={"propertyListRegister"} defaultClose={true} />)
        setPropertyDetailsList([...currentPropertyList]);
    }

    const toggleAccordian = (accordianIndex) => {
      const element = document.getElementsByClassName("accordianBox")[accordianIndex];
      // let accordianTitle = element.getElementsByClassName("accordianTitle")[0]
      let divToActUpon = element.getElementsByClassName("accordianBoxContainer")[0].getElementsByClassName("propertyDiv")[0];
      if(divToActUpon.style.display == "none"){
        divToActUpon.style.display = "block"
      }
      else{
        divToActUpon.style.display = "none"
      }
    }
  
    const accordianViewContainer = (propertyList) => {
      let tempView = [];
      propertyList.forEach((propertyView, index) => {
        tempView.push(
          <div className='accordianBox' key={index} id={"propertyAccordian-" + index}>
            <span className="accordianTitle" onClick={toggleAccordian.bind(this, index)}>
              <MdArrowRight size={20} color='rgba(0,0,0,0.25)' />
              <label>Unit #{index + 1}</label>
            </span>
            <MdClose size={18} color='rgba(0,0,0,0.35)' className='removeUnit' onClick={removePropertyUnit.bind(this, index)} />
            <div className='accordianBoxContainer'>
              {propertyView}
            </div>
          </div>
        )
      })
      return tempView;
    }
  
    const removePropertyUnit = (index) => {
      let currentPropertyList = propertyDetailsList;
      if(currentPropertyList.length > 1){
        let newPropertyList = currentPropertyList.filter((e, i) => i !== index);
        setPropertyDetailsList([...newPropertyList]);
      }
    }
  

    const editSearchAddress = () => {
        setSelectedAddress(null);
        setSelectedAddressPlaceID(null)
      }   


    const handlePropertySubmit = (e) => {
        e.preventDefault();
        setLoading(true)
    
        let messageDiv = document.getElementsByClassName("message").item(0);
    
        if(selectedAddress == null){
          setLoading(false);
          return new FormException(null, "Please Select an Address from dropdown", messageDiv)
        }
    
        const unitLength = propertyDetailsList.length == 0 ? 1 : propertyDetailsList.length ;
    
        let propertyDetail = {
          userId : isLoggedIn().data.id,
          type : propertySetupType,
          address : selectedAddress,
          placeID : selectedAddressPlaceID,
          units : []
        }
        
        for(let j = 0; j < unitLength; j++){
          let unit = {
            propertyName : (unitLength > 1) ? e.target.propertyName[j].value : e.target.propertyName.value,
            renterOccupied : "false",
            leaseFrom : "",
            leaseTo : "",
            rentalPrice : (unitLength > 1) ? e.target.rentalPrice[j].value : e.target.rentalPrice.value,
            // partialPayment : (unitLength > 1) ? e.target.partialPayment[j].value : e.target.partialPayment.value,
            partialPayment : false,
            penalty : (unitLength > 1) ? e.target.penalty[j].value : e.target.penalty.value,
            penaltyPrice : (((unitLength > 1) ? e.target.penalty[j].value : e.target.penalty.value)
            == "true" ) ? (unitLength > 1) ? e.target?.penaltyPrice[j]?.value : e.target?.penaltyPrice?.value : "",
            renterDetails : {
              confirmed : false,
              first_name : (unitLength > 1) ? e.target.first_name[j].value : e.target.first_name.value,
              last_name : (unitLength > 1) ? e.target.last_name[j].value : e.target.last_name.value,
              email : (unitLength > 1) ? e.target.email[j].value : e.target.email.value,
              phone : (unitLength > 1) ? e.target.phone[j].value : e.target.phone.value,
            }
          }
          propertyDetail.units.push(unit);
        }
        createNewProperty(propertyDetail).then((resp) => {
          setLoading(false)
          if(resp.status){
            pageChange("ManageProperty")
          }
          else{
            return new FormException(e, "Something Went Wrong", messageDiv);
          }
        })
        .catch((error) => {
          setLoading(false)
          return new FormException(e, error.message, messageDiv);
        })
      }

      const handleSearchChange = (e) => {
        let UI_LIST = document.getElementById("mapSearchDataList");
        if(e.target.value.trim().length > 2){
          addressSearch(e.target.value)
          .then((response) => {
            if(response.length > 0){
              UI_LIST.innerHTML = null;
              response.forEach((element) => {
                var li = document.createElement('li');
                let displayAddr = element.formatted_address
                if(!element.formatted_address.includes(element.name)){
                  displayAddr = `${element.formatted_address}`
                }
                li.appendChild(document.createTextNode(`${displayAddr}`));
                
                li.onclick = handlePMSearchItemSelect.bind(this, `${element.formatted_address}`, `${element.place_id}`);
                
                UI_LIST.appendChild(li)
              })
              UI_LIST.style.display = "block"
            }
            else{
              UI_LIST.style.display = "none";
            }
          })
        }
        else{
          UI_LIST.style.display = "none";
        }
      }
      const updatePropertyType = (e) => {
        setPropertySetupType(e.target.value);
        if(e.target.value == "multiUnit"){
          let tempArray = [
            <PropertyManagerSetup listName={"propertyListRegister"} />
          ]
          setPropertyDetailsList([...tempArray]);
        }
      }

  const handlePMSearchItemSelect = (address, placeID) => {
    isAddressAvailable(placeID)
    .then((resp) => {
      if(resp.length == 0){
        setSelectedAddress(address);
        setSelectedAddressPlaceID(placeID)
      }
      else{
        let e = {
          target :  {
            value : ""
          }
        }
        handleSearchChange(e)
        setSelectedAddress(null);
        setSelectedAddressPlaceID(null)
        let messageDiv = document.getElementsByClassName("message").item(0);
        FormException(null, "Property Already registered from another manager", messageDiv);
      }
    })
    .catch()
  
}
    return (
      <>
        <CustomWrapper
            width='100%'
            maxWidth={1500}
            height='600px'
            maxHeight={"100%"}
            paddingHorizontal={30}
            paddingVertical={30}
        >
            <form className='accountSetupForm fadeIn' onSubmit={handlePropertySubmit} style={{maxWidth : 900, overflow : 'auto', height : '100%', padding : 20}}>
                <div className='columnFormInput'>
                    {
                    selectedAddress == null && 
                    <div className='formInput'>
                        <div className='iconFormInput'>
                        <MdSearch color='#526035' size={25}/>
                        <input type='text' name="mapSearch"  onChange={handleSearchChange} placeholder='Search...' />
                        </div>
                        <ul className='searchDatalist' id='mapSearchDataList'>
                        <li>Option 1</li>
                        <li>Option 2</li>
                        <li>Option 3</li>
                        <li>Option 4</li>
                        </ul>
                    </div>
                    }
                    {
                    selectedAddress == null &&
                    <div className='message'>SampleText</div>
                    }
                    {
                    selectedAddress != null &&
                    <div className='editAddress'>
                        <div className='titleMessage' style={{margin : 'unset', marginLeft : 40}}>
                        {selectedAddress}
                        </div>
                        <MdOutlineEdit size={25} color='rgba(0,0,0,0.45)' onClick={editSearchAddress}/>
                    </div>
                    }
                    <div className='formInput'>
                    <label className='formRadio'>
                        <input type='radio' name='unitType' value={"singleUnit"} onChange={updatePropertyType} />
                        <span>Single Unit</span>
                    </label>
                    <label className='formRadio'>
                        <input type='radio' name='unitType' value={"multiUnit"} onChange={updatePropertyType} />
                        <span>Multi Unit</span>
                    </label>
                    </div>
                </div>
                {
                    propertySetupType === 'singleUnit' &&
                    <PropertyManagerSetup listName={"propertyListRegister"}/>
                }
                {
                    propertySetupType === 'multiUnit' &&

                    <div className='accordian'>
                    {
                        propertyDetailsList && accordianViewContainer(propertyDetailsList)
                    }
                    </div>
                }
                {
                    propertySetupType === 'multiUnit' &&
                    <HighlighterButton
                    width='150px'
                    fontSize='12px'
                    margin='0'
                    paddingVertical='8px'
                    type='button'
                    onPressFunction={addPropertyUnit}
                    >
                    + Add Unit
                    </HighlighterButton>
                }
                <div className='pageSubmit'>
                    <Button
                      primary={false}
                      margin='0'
                      paddingVertical='8px'
                      type='button'
                      onPressFunction={() => pageChange("ManageProperty")}
                    >Cancel</Button>
                    <Button 
                    primary={true}
                    paddingVertical='8px'
                    margin='0'
                    type='submit'>Done</Button>
                </div>
            </form>
        </CustomWrapper>
        {isLoading && <Loader />}
      </>
    )
}

export default AddProperty