import React from 'react'
import "./Button.css"

function Button({ className="", margin = "20px", paddingVertical = "15px", paddingHorizontal="0", type="submit", primary = true, children, onPressFunction = () => {}, disable = false}) {
  return (
    <button 
      style={
        {
          margin : margin, 
          padding : `${paddingVertical} ${paddingHorizontal}`,
          background : disable ? "#b6b6b6" : "",
          cursor : disable ? "not-allowed" : ""
        }
      } 
      className={'signInButton ' + (primary?'primary':'secondary') + ` ${className}`}
      onClick={onPressFunction} 
      disabled={disable}
      type={type}>
        {children}
    </button>
  )
}

export default Button