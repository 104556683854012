import { fetcher } from "./auth.service";

const SERVER_URL = process.env.REACT_APP_SERVER_URL

//Authentication APIs
export const GENERATE_OTP = SERVER_URL + "/otp/generate";
export const VERIFY_OTP = SERVER_URL + "/otp/verify";

//NOTIFICATION APIs
export const NOTIFICATION = SERVER_URL + "/notification";
export const GET_NOTIFICATION_BY_USER = NOTIFICATION + "/user";

//User APIs
export const ACCOUNT = SERVER_URL + "/user";
export const USER_DETAILS_FIND_BY_PHONE = SERVER_URL + "/user/findBy/phone";
export const USER_DETAILS_FIND_BY_EMAIL = SERVER_URL + "/user/findBy/email";

// Role APIs
const GET_ALL_ROLES = SERVER_URL + "/role";

//Property APIs
export const CREATE_NEW_PROPERTY = SERVER_URL + "/properties/new"
export const GET_PROPERTY_UNITS_FOR_RENTER = SERVER_URL + "/properties/renter"
export const GET_RENT_DUES = SERVER_URL + "/properties/renter/getDues"
export const GET_DUES_FOR_PM = SERVER_URL + "/properties/pm/getDues"
export const GET_ALL_PROPERTIES = SERVER_URL + "/properties"

//Payment APIs
export const CREATE_NEW_PAYMENT_METHOD = SERVER_URL + "/payment-token"
export const UPDATE_PAYMENT_METHOD = SERVER_URL + "/payment-token/update"

//Transaction APIs
export const LIST_ALL_TRANSATION_FOR_USERS = SERVER_URL + "/transaction/user"
export const TRANSACTION_INSITE_RENTER = SERVER_URL + "/transaction/renter/insite"
export const TRANSACTION_INSITE_PM = SERVER_URL + "/transaction/pm/insite"
export const LIST_ALL_TRANSATION_FOR_PROPERTY_MANAGER = SERVER_URL + "/transaction/property_manager"
export const CREATE_NEW_TRANSACTION = SERVER_URL + "/transaction/new"

export const getUserRoles = async () => {
    const data = await fetcher(GET_ALL_ROLES , 'GET')
    return data.json();
}

export const openSupportIssue = async (input) => {
    const data = await fetcher(
        ACCOUNT + "/raiseSupportTicket" ,
        "POST",
        input,
        "application/json"
    )
    return data.status;    

}