import { fetcher } from "./auth.service";
import { ACCOUNT, GET_NOTIFICATION_BY_USER, NOTIFICATION, USER_DETAILS_FIND_BY_EMAIL, USER_DETAILS_FIND_BY_PHONE } from "./base.service";

export const fetchUserDetails = async (source, type) => {
    const GET_URL = (type === "email") ? USER_DETAILS_FIND_BY_EMAIL : USER_DETAILS_FIND_BY_PHONE;
    const data = await fetcher(GET_URL + "/" + source , 
        'GET'
    )
    return data.json();
}

export const accountSetup = async (id, input) => {
    const data = await fetcher(ACCOUNT + "/" + id , 
        'PATCH',
        input,
        "application/json"
    )
    return data.json();
}

export const updateAccountRole = async (id, input) => {
    const data = await fetcher(ACCOUNT + "/" + id + "/role" , 
        'PATCH',
        input,
        "application/json"
    )
    return data.json();
}

export const createAccountWithGoogle = async (input) => {
    const data = await fetch(ACCOUNT + "/new" , {
        body : JSON.stringify(input),
        headers : {"Content-Type" : "application/json"},
        method : 'POST'
    })
    return data.json();
}

export const fetchNotificationByUser = async (id) => {
    const data = await fetcher(GET_NOTIFICATION_BY_USER + "/" + id , 'GET')
    return data.json();
}

export const readNotice = async (id) => {
    const data = await fetcher(NOTIFICATION + "/" + id , 
        'PATCH',
        {isRead : true}
    )
    return data.json();
}