import React, { useContext, useEffect, useState } from 'react'
import "./ManageProperty.css"
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { SampleAccountData } from './SampleAccountData';
import CustomWrapper from '../wrapper/CustomWrapper';
import Button from '../Button/Button';
import { getAllProperties } from '../../services/property.service';
import { AppContext } from '../../App';
import { Loader } from '../Loader/Loader';

function ManageProperty({userData = null, editFunction=null, onAddNew}) {
    const [propertyList, setPropertyList] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
      document.title = "Manage Payment Accounts | Ando";
      setLoading(true)
      getAllProperties(userData.data.id)
      .then((resp) => {
        setLoading(false)
          setPropertyList(resp.map((e) => {
            e.units = e.units.map((u) => {
                return {
                    id : u?.id,
                    propertyName : u?.propertyName,
                    renterOccupied : u?.renterOccupied,
                    leaseFrom : u?.leaseFrom,
                    leaseTo : u?.leaseTo,
                    rentalPrice : u?.rentalPrice,
                    partialPayment : u?.partialPayment,
                    penalty : u?.penalty,
                    penaltyPrice : u?.penaltyPrice,
                    renterDetails : {
                        first_name : u?.renterDetails?.first_name,
                        last_name : u?.renterDetails?.last_name,
                        email : u?.renterDetails?.email,
                        phone : u?.renterDetails?.phone,
                        confirmed : u?.renterDetails?.confirmed
                    }
                }
            })
            if(e?.units?.length === 0){
                e.units = [{
                    propertyName : null,
                    renterOccupied : null,
                    leaseFrom : null,
                    leaseTo : null,
                    rentalPrice : null,
                    partialPayment : null,
                    penalty : null,
                    penaltyPrice : null,
                    renterDetails : {
                        first_name : null,
                        last_name : null,
                        email : null,
                        phone : null,
                        confirmed : null
                    }
                }
                ]
            }
              return (
                  <PropertyBox 
                      editFunction={editFunction}
                      propertyJson={e}
                  />
              )
          }))
      })

    }, []);

    return (
        <>
            <CustomWrapper
            width='100%'
            maxWidth={2400}
            height='600px'
            maxHeight={"100%"}
            paddingHorizontal={30}
            paddingVertical={30}
            >
                <div className='paymentOptionContainer' style={{margin : 10}}>
                    <div className='manageAccountHeader'>
                        <div className='title'>Manage Property</div>
                        <Button
                            className='minButton'
                            margin='10px'
                            onPressFunction={onAddNew}
                        >
                            <MdAdd color='#FFFFFF' size={16}/>
                            Add Property
                        </Button>
                    </div>
                    <div className='accountsContainer scroller' style={{
                            display: 'flex',
                            flexDirection : "column",
                            gap: 20,
                            alignItems: 'center',
                            justifyContent: 'space-evenly'
                        }}
                    >
                        {propertyList}
                    </div>
                </div>
            </CustomWrapper>
            {isLoading && <Loader />}
        </>
    )
}


const PropertyBox = ({editFunction, propertyJson}) => {
    return (
        <div className='accountPr'>
            <div className='leftDiv'>
                <div className='accountTitle'>{propertyJson.address}</div>
                <div className='endingWith'>Number of Units : {propertyJson.units.length}</div>
            </div>
            <div className='rightDiv'>
                <div className='primaryTag' style={{visibility : 'hidden'}}>Primary Account</div>
                <div className='editLink'
                    onClick={() => {editFunction(propertyJson)}}
                > <MdDelete color='rgba(0,0,0,0.85)' size={16} /> Delete</div>
                <div className='editLink'
                    style={{
                        right : 75
                    }}
                    onClick={() => {editFunction(propertyJson)}}
                > <MdEdit color='rgba(0,0,0,0.85)' size={16} /> Edit</div>
            </div>
        </div>
    )
}

export default ManageProperty