import React from 'react';
import "./Loader.css";
import LoaderImg from "../../assets/svgs/Loader.svg"
import { dotWave } from 'ldrs';
dotWave.register();

export function Loader() {
  return (
    <React.Fragment>
      <div className='loaderContainer'>
        <l-dot-wave
          size="50"
          speed="1" 
          color="white" 
        ></l-dot-wave>
      </div>
    </React.Fragment>
  )
}
export function MiniLoader() {
  return (
    <React.Fragment>
        <l-dot-wave
          size="25"
          speed="1" 
          color="#526035" 
        ></l-dot-wave>
    </React.Fragment>
  )
}