import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../wrapper/Wrapper";
import Button from "../Button/Button";
import "./MyProperties.css";
import { Link, useNavigate } from "react-router-dom";
import { confirmRenterProperty, getAllProperties, loadRenterDetails, moveOutRenter, raiseDispute, searchPropertyForRenter } from "../../services/property.service";
import { getAllTrancationsForUser } from "../../services/payment.method.service";
import { MiniLoader } from "../Loader/Loader";
import { AppContext } from "../../App";
import PopupWrapper from "../wrapper/PopupWrapper";
import Calendar from "../Calendar/Calendar";
import { addressSearch, cacheUser, logout } from "../../utility/util";
import { MdOutlineEdit, MdSearch } from "react-icons/md";
import { FormException } from "../../utility/exceptions";

export default function MyProperties({ userData = null, activeUser = null }) {
  const [totalProperties, setTotalProperties] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [totalUnits, setTotalUnits] = useState(0);
  const [totalActiveRenters, setTotalActiveRenters] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [address, setAddress] = useState("");
  const [rentMonth, setRentMonth] = useState(new Date())
  const [showMoveOutPopup, setShowMoveOutPopup] = useState(false);
  const [moveOutStep, setMoveOutStep] = useState(0);
  const [moveOutDate, setMoveOutDate] = useState(new Date())
  const [renterLoaded, setRenterLoaded] = useState(false);

  const [moveOutLoading, setMoveOutLoading] = useState(false);
  const [renterDetails, setRenterDetails] = useState(null);
  const [renterDetailsArray, setRenterDetailsArray] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedAddressPlaceID, setSelectedAddressPlaceID] = useState(null);
  const [multiUnitPropertiesFound, setMultiUnitPropertiesFound] = useState(false);
  const [noAddressExist, setNoAddressExist] = useState(false);
  const [confirmRenter, setConfirmRenter] = useState(null);
  const [popupDiv, setPopupDiv] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [propertyID, setPropertyID] = useState("")
  const [unitID, setUnitID] = useState("")

  const appContext = useContext(AppContext);

  const navigate = useNavigate();

  const addProperty = () => {
    navigate("/property_manager/properties/add");
  };

  const payRent = () => {
    navigate("/renter/payment")
  }
  const performLogout = () => {
    if(logout()){
      navigate("/login");
    }
  }
  const moveOut = () => {    
    if(pendingAmount == 0){
      moveOutRenter(userData.data.id)
      .then((resp) => {
        
      })
      .catch((err) => {
        console.log(err)
        appContext.toastRef.current.launch(err);
      })
    }
    else{
      appContext.toastRef.current.launch("Please Settle Rent before moving out");
    }
  }

  const moveOutButton = () => {
    if(pendingAmount <= 0){
      setShowMoveOutPopup(true);
    }
    else{
      appContext.toastRef.current.launch("Please Settle Rent before moving out");
    }
    
  }

  const closeMoveOutPopUp = () => {
    if(renterLoaded){
      setShowMoveOutPopup(false)
      setMoveOutStep(0)
      setMoveOutDate(new Date())
    }
    else{
      performLogout()
    }
  }
  const handleSearchChange = (e) => {
    let UI_LIST = document.getElementById("mapSearchDataList");
    if(e.target.value.trim().length > 2){
      addressSearch(e.target.value)
      .then((response) => {
        if(response.length > 0){
          UI_LIST.innerHTML = null;
          response.forEach((element) => {
            var li = document.createElement('li');
            let displayAddr = element.formatted_address
            if(!element.formatted_address.includes(element.name)){
              displayAddr = `${element.formatted_address}`
            }
            li.appendChild(document.createTextNode(`${displayAddr}`));
            li.onclick = handleRenterSearchItemSelect.bind(this, `${element.formatted_address}`, `${element.place_id}`);
            UI_LIST.appendChild(li)
          })
          UI_LIST.style.display = "block"
        }
        else{
          UI_LIST.style.display = "none";
        }
      })
    }
    else{
      UI_LIST.style.display = "none";
    }
  }
  const filterUnitsByEmailOrPhone = (units, email, phone) =>{
    return units.filter((element) => {
      return (
        element.renterDetails.phone == phone ||
        element.renterDetails.email == email ||
        element.renterDetails.phone == null ||
        element.renterDetails.email == null
      )
    })
  }

  const editSearchAddress = () => {
    setSelectedAddress(null);
    setSelectedAddressPlaceID(null);
  }
  const unitListGenerator = () => {
    let list = [];
    if(renterDetailsArray?.length > 1){
      renterDetailsArray.forEach((e, index)=> {
        let chilList = [];
        e.units.forEach((element) =>{
          chilList.push(<div className='unitContainer' onClick={selectUnitForRenter.bind(this, element.propertyName, index, e?.address, e?.placeID)}>{element.propertyName}</div>)
        })
        list.push(
          <div className='propertyHolder'>
            <div>{e.address}</div>
            <div className='unitList'>{chilList}</div>
          </div>
        )
      })
    }
    else{
      renterDetailsArray[0].units.forEach((element) =>{
        list.push(<div className='unitContainer' onClick={selectUnitForRenter.bind(this, element.propertyName, 0, renterDetailsArray[0]?.address, renterDetailsArray[0]?.placeID)}>{element.propertyName}</div>)
      })
    }

    return list;
  }

  const selectUnitForRenter = (propertyName, index, address = null, placeID = null, element) => {
    if(address){
      setSelectedAddress(address)
      setSelectedAddressPlaceID(placeID)
    }

    let el = document.getElementsByClassName("unitContainer");
    for(let q = 0; q< el.length ; q++){
      el.item(q).style.background="none"
    }
    element.target.style.background = "#e3e58022"

    let unit = renterDetailsArray[index].units.filter((e) => {
      return e.propertyName == propertyName
    })
    let temp = Object.create(renterDetailsArray);
    temp.units = unit;
    temp.address = renterDetailsArray[index].address;
    temp.placeID = renterDetailsArray[index].placeID;
    setRenterDetails(temp);
    setSelectedAddress(renterDetailsArray[index].address)
    setSelectedAddressPlaceID(renterDetailsArray[index].placeID)
    setMultiUnitPropertiesFound(false);
  }


  const handleRenterSearchItemSelect = (address, placeID) => {
    let messageDiv = document.getElementsByClassName("message").item(0);
    try{
      searchPropertyForRenter(userData?.data.phone_number, userData?.data.email, placeID)
      .then((resp) => {
        if(resp.length > 0){
          setNoAddressExist(false)
          let data = resp;
          data[0].units = filterUnitsByEmailOrPhone(resp[0].units, userData.email, userData.phone_number);
          if(data[0].units.length == 1){
            setRenterDetails(data[0])
            setSelectedAddress(data[0].address)
            setSelectedAddressPlaceID(data[0].placeID)
            setMultiUnitPropertiesFound(false);
          }
          else{
            setRenterDetails(null);
            setMultiUnitPropertiesFound(true);
            setRenterDetailsArray(resp)
          }
          setSelectedAddress(address);
          setSelectedAddressPlaceID(placeID)
        }
        else if (resp.statusCode == 400){          
          setMultiUnitPropertiesFound(false);
          setRenterDetails(null);
          setNoAddressExist(true)
          return FormException(null, "Something Went Wrong", messageDiv);
        }
        else if (resp.statusCode == 500){
          setMultiUnitPropertiesFound(false);
          setRenterDetails(null);
          setNoAddressExist(true)
        }
        else if (resp?.message?.includes("No Address Found")){
          setMultiUnitPropertiesFound(false);
          setRenterDetails(null);
          setNoAddressExist(true)
          return FormException(null, resp?.message, messageDiv);
        }
        else if (resp?.message?.includes("someone")){
          setMultiUnitPropertiesFound(false);
          setRenterDetails(null);
          setNoAddressExist(true);
        }
        else{
          setMultiUnitPropertiesFound(false);
          setRenterDetails(null);
        }
        messageDiv.style.display = "none"
      })
    }
    catch{
      setMultiUnitPropertiesFound(false);
      setRenterDetails(null);
      setNoAddressExist(true)
    }
    let UI_LIST = document.getElementById("mapSearchDataList");
    UI_LIST.style.display = "none";
    
  }

  const submitRenterDetails = (e) => {
    e.preventDefault();
    setMoveOutLoading(true)
    let messageDiv = document.getElementsByClassName("message").item(0);
    if(selectedAddress === null){
      setMoveOutLoading(false)
      return FormException(e, "No Address Selected", messageDiv);
    }

    let renterDesc = null;
    let renterSelectedOption = confirmRenter;
    if(renterSelectedOption === null){
      setMoveOutLoading(false)
      return FormException(e, "Select Confirm or Raise Dispute Option", messageDiv);
    }
    
    if(renterSelectedOption === "dispute"){
      renterDesc = document.getElementById("descRenter").value.trim();
      if(renterDesc.length < 2){
        setMoveOutLoading(false)
        return FormException(e, "Dispute description required", messageDiv);
      }
      raiseDispute(
        userData.data.id, 
        renterDesc, 
        renterDetails.placeID, 
        renterDetails.units[0].propertyName
      ).then((resp) => {
        setMoveOutLoading(false)
        if(resp.status){
          setPopupMessage("Dispute raised successfully, Property Manager will soon connect with you");
          setPopupDiv(true);
        }
      })
      .catch((error) => {
        setMoveOutLoading(false)
        return FormException(e, "Something Went Wrong", messageDiv);
      })
    }
    else{
      confirmRenterProperty(
        userData.data.id, 
        renterDetails.placeID, 
        renterDetails.units[0].propertyName
      ).then((res) =>{
        if(res.status){
          cacheUser(res.data)
          window.location.reload()
        }
        else{
          return FormException(e, "Something Went Wrong", messageDiv);
        }
        setMoveOutLoading(false)
      })
      .catch((error) => {
        setMoveOutLoading(false)
        return FormException(e, "Something Went Wrong", messageDiv);
      })
    }
  }

  const notMovingToNew = () => {
    setMoveOutLoading(true)
    moveOutRenter({
      propertyId : propertyID,
      unitId : unitID,
      renterId : userData?.data?.id,
      moveOutDate : moveOutDate?.toISOString().split("T")[0]
    })
    .then((res) => {
      if(res?.status){
        appContext.toastRef.current.launch(res?.message);
      }
      else{
        console.log(res);        
        appContext.toastRef.current.launch(res?.message);
      }
    })
    .catch((err) => {
      console.log(err);
      appContext.toastRef.current.launch(err.message);
    })
    .finally(() => {
      setMoveOutLoading(false)
      setShowMoveOutPopup(false)
    })
  }

  const movingWithNew = () => {
    setMoveOutLoading(true)
    moveOutRenter({
      propertyId : propertyID,
      unitId : unitID,
      renterId : userData?.data?.id,
      moveOutDate : moveOutDate?.toISOString().split("T")[0]
    })
    .then((res) => {
      if(res?.status){
        appContext.toastRef.current.launch(res?.message);
        setMoveOutLoading(false)
        setMoveOutStep(2)
      }
      else{
        console.log(res);        
        appContext.toastRef.current.launch(res?.message);
        setMoveOutLoading(false)
        setMoveOutStep(2)
      }
    })
    .catch((err) => {
      console.log(err);
      appContext.toastRef.current.launch(err.message);
      setMoveOutLoading(false)
      setShowMoveOutPopup(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    if(activeUser === "propertyManager"){
      getAllProperties(userData.data.id).then((resp) => {
        setLoading(false)
        let tUnits = 0;
        let tActiveUsers = 0;
  
        resp.forEach((e) => {
          tUnits += e.units.length;
          e.units.forEach((unit) => {
            if (unit.renterDetails.confirmed) tActiveUsers += 1;
          });
        });
        setTotalProperties(resp.length);
        setTotalUnits(tUnits);
        setTotalActiveRenters(tActiveUsers);
      });
    }
    else{
      let totalRentFromStart = 0;
      let rentPerMonth = 0;
      let currentDate = new Date();
      let renterConfirmedDate = new Date();
      loadRenterDetails(userData?.data?.phone_number, userData?.data?.email, true)
      .then((resp) => {
        setLoading(false)
        if(resp.length > 0){
          setRenterLoaded(true)
          setPropertyID(resp[0]?.id)
          setUnitID(resp[0].units[0]?.id)
          setAddress(`${resp[0].units[0].propertyName} - ${resp[0].address}`);
          renterConfirmedDate = new Date(resp[0].units[0].renterConfirmedDate);
          rentPerMonth = resp[0].units[0].rentalPrice;
          let diffDate = currentDate - renterConfirmedDate;
          let totalMonths = Math.floor(diffDate / 1000 / 60 / 60 / 24 / 30) + 1;
          totalRentFromStart = totalMonths * rentPerMonth;
        }
        else{
          setShowMoveOutPopup(true);
          setMoveOutStep(2)
        }
      })
      .then(() => {
        fetchTransactions(totalRentFromStart);
      })
    }

    const fetchTransactions = (totalRentFromStart) => {
      setLoading(true)
      getAllTrancationsForUser(userData?.data?.id)
      .then((resp) => {
        setLoading(false)
        let amountPaidTillDate = 0;
        if(resp.status){
          resp.data?.forEach((element) => { 
            amountPaidTillDate += parseInt(element.amount);
          })
          setPendingAmount(totalRentFromStart - amountPaidTillDate);
        }
        else{
          setPendingAmount(totalRentFromStart - amountPaidTillDate);
        }
      })
    }
    let currentDate = new Date();
    if(currentDate?.getMonth() === rentMonth?.getMonth()){
      currentDate?.setMonth(currentDate?.getMonth())
      setRentMonth(currentDate)
    }
  }, []);

  return (
    <>
      <Wrapper width="100%" maxWidth={3000}>
        {activeUser === "propertyManager" && (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="currentPropertyActionTitle">
                <img
                  src={require("../../assets/Home_duotone.png")}
                  className="home-icon"
                />
                <label>My Properties</label>
              </div>
              <Button
                primary={true}
                paddingVertical="8px"
                paddingHorizontal="1px"
                margin="0px"
                type="button"
                className={"signInButtonCustom"}
                onPressFunction={addProperty}
              >
                + Add Property
              </Button>
            </div>
            <div className="prop-row">
              <div>
                <div>
                  <span className="prop-count">{!isLoading ? totalProperties : <MiniLoader />}</span>
                  <br />
                  <span className="fade-in">Properties</span>
                </div>
              </div>
              <div>
                <div>
                  <span className="prop-count">{!isLoading ? totalUnits : <MiniLoader />}</span>
                  <br />
                  <span className="fade-in">Apartment Units</span>
                </div>
              </div>
              <div>
                <div>
                  <span className="prop-count">{!isLoading ? totalActiveRenters : <MiniLoader />}</span>
                  <br />
                  <span className="fade-in">Active Renters</span>
                </div>
              </div>
            </div>
            <div className="view">
              <Link onClick={() => {navigate("/property_manager/properties")}}>View Details</Link>
            </div>
          </div>
        )}

        {activeUser === "renter" && (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              
                <div className="currentPropertyActionTitle" style={{flex:1, alignItems : 'center', marginTop : '10px'}}>
                  <div style={{display : "flex", flexDirection : "column", alignItems : "center"}}>
                    <img
                      src={require("../../assets/Home_duotone.png")}
                      className="home-icon"
                    />
                    <div className="view" style={{paddingTop : "5px"}} onClick={moveOutButton}>
                      <Link>Moving Out?</Link>
                    </div>
                  </div>
                  <label>My Place</label>
                  <div className="userAddress">{isLoading ? <MiniLoader /> : address}</div>
                </div>
              
              <Button
                primary={true}
                paddingVertical="8px"
                paddingHorizontal="1px"
                margin="0px"
                type="button"
                className={"signInButtonCustom"}
                onPressFunction={payRent}
              >
                Pay Rent
              </Button>
            </div>
            
            <div className="status-container">
              <label>{rentMonth.toLocaleString('default', { month: 'long' })} Rent Status:</label>
              {
                isLoading && <MiniLoader />
              }
              {
                !isLoading && 
                <>
                  <span className="status" style={{color :(pendingAmount > 0 ? "tomato" : null)}}>{pendingAmount > 0 ? "Due" : "Paid"}</span>
                  {
                    pendingAmount == 0 &&
                    <img
                      src={require("../../assets/cash.png")}
                      className="cash-icon"
                    />
                  }
                </>
              }
            </div>
            <div className="view">
              <Link onClick={() => {navigate("/renter/payment")}}>View Details</Link>
            </div>
          </div>
        )}
      </Wrapper>
      {
        showMoveOutPopup &&
        <PopupWrapper
          onClose={closeMoveOutPopUp}
        >
          <div style={{
            display : 'flex',
            flexDirection : "column",
            gap : "10px"
          }}>
            <div style={{
              display : 'flex',
              flexDirection : "column",
              gap : "2px"              
            }}>
              <p style={{fontSize : "22px", color : "rgba(0,0,0,0.85)", margin : "0px"}}>Moving Out?</p>
              {
                moveOutStep == 0 &&
                <p style={{fontSize : "18px", color : "rgba(0,0,0,0.7)", margin : "0px"}}>Select Your Move out date.</p>
              }
              {
                moveOutStep == 1 &&
                <p style={{fontSize : "18px", color : "rgba(0,0,0,0.7)", margin : "0px"}}>Are you moving to a new place?</p>
              }
              {
                moveOutStep == 2 &&
                <p style={{fontSize : "18px", color : "rgba(0,0,0,0.7)", margin : "0px"}}>Find Your Place</p>
              }
            </div>
            <div style={{display : "flex"}}>
              {
                moveOutStep == 0 &&
                <div style={{display : "flex", flexDirection : "column", gap: "5px"}}>
                  <Calendar
                    currentDate={moveOutDate}
                    setCurrentDate={setMoveOutDate}
                    amount={50}
                  />
                  <div style={{
                    display : "flex",
                    flexDirection : "row",
                    justifyContent : "flex-end",
                    gap : "10px"
                  }}
                  >
                    <Button
                      primary={false}
                      paddingVertical="8px"
                      paddingHorizontal="1px"
                      margin="0px"
                      type="button"
                      className={"signInButtonCustom"}
                      onPressFunction={closeMoveOutPopUp}
                    >
                      Cancel
                    </Button>
                    <Button
                      primary={true}
                      paddingVertical="8px"
                      paddingHorizontal="1px"
                      margin="0px"
                      type="button"
                      className={"signInButtonCustom"}
                      onPressFunction={() => {
                        setMoveOutStep(1)
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              }
              {
                !moveOutLoading && moveOutStep == 1 &&
                <div className='accountTypeContainer' style={{gap : "15px", margin : "20px"}}>
                  <div className={'typeBox primaryBorder'} key={1}>
                    <div className='typeTitleM'>No, I'm not moving</div>
                    <div className='typeDescriptionM'>Good luck finding your next place and come back when you’re ready to set it up on ando</div>
                    <div className={'typeSelectButton primaryBackground'} onClick={notMovingToNew}>No, I'm not moving</div>
                  </div>
                  <div className={'typeBox highlightBorder'} key={2}>
                    <div className='typeTitleM'>Yes, I'm moving</div>
                    <div className='typeDescriptionM'>Nice! Let’s get you set up in your new place on ando right now</div>
                    <div className={'typeSelectButton highlightBackground'} onClick={movingWithNew}>Yes, I'm moving</div>
                  </div>
                </div>
              }
              {
                !moveOutLoading && moveOutStep == 2 &&
                <form onSubmit={submitRenterDetails} style={{marginTop : "15px", minWidth : "680px", padding : "5px", minHeight : "300px", display : "flex", flexDirection : "column"}}>
                  <div style={{flex : 1}}>
                    {
                      noAddressExist &&
                      <div className='titleMessage'>
                        Opps! Address is not registered yet.
                      </div>

                    }
                  {
                    !noAddressExist && selectedAddress == null && 
                    <>
                      <div className='formInput'>
                        <div className='iconFormInput'>
                          <MdSearch color='#526035' size={25}/>
                          <input type='text' name="mapSearch"  onChange={handleSearchChange} placeholder='Search...' />
                        </div>
                        <ul className='searchDatalist scroller' style={{overflowY : "auto", maxHeight : "200px"}} id='mapSearchDataList'>
                          <li>Option 1</li>
                          <li>Option 2</li>
                          <li>Option 3</li>
                          <li>Option 4</li>
                        </ul>
                      </div>
                      <div className='message' style={{marginTop : "5px"}}>sample text</div>
                    </>
                  }
                  {
                    !noAddressExist && selectedAddress != null &&
                    <div className='editAddress'>
                      <div className='titleMessage' style={{margin : 'unset', marginLeft : 30, marginTop : 10}}>
                      {selectedAddress}
                      </div>
                      <MdOutlineEdit size={25} color='rgba(0,0,0,0.45)' onClick={editSearchAddress}/>
                    </div>
                  }
                  {
                    !noAddressExist && renterDetails == null && multiUnitPropertiesFound &&
                    <>
                      <div className='subMessage'>
                        Multiple Properties/Units found. Select your unit.
                      </div>
                      <div className='multiUnitContainer'>
                        {unitListGenerator()}
                      </div>
                    </>
                  }
                  {
                    !noAddressExist && renterDetails != null && !multiUnitPropertiesFound &&
                    <>
                      <div className='message' style={{marginTop : "5px"}}>sample text</div>
                      <div className='titleBold' style={{marginLeft : "30px", marginBottom : "10px", marginTop : "20px"}}>Confirm Property Details : </div>
                      <div className='renterDetails'>
                        <div className='leftPane'>
                          <div className='renterInfo'>
                            <label>Property Name :&nbsp;</label>
                            <span>{renterDetails.units[0].propertyName}</span>
                          </div>
                          <div className='renterInfo'>
                            <label>Rental Price :&nbsp;</label>
                            <span>${renterDetails.units[0].rentalPrice}</span>
                          </div>
                          <div className='renterInfo'>
                            <label>Penatly for late payments :&nbsp;</label>
                            <span>{renterDetails.units[0].penalty  == "true"? "Yes" : "No"}</span>
                          </div>
                        </div>
                        <div className='rightPane'>
                          <div className='formInput'>
                            <label className='formRadio'>
                              <input type='radio' name='confirmRenter' value={"confirm"} onClick={() => { setConfirmRenter("confirm")}} />
                              <span>Confirm Details</span>
                            </label>
                            <label className='formRadio'>
                              <input type='radio' name='confirmRenter' value={"dispute"}  onClick={() => { setConfirmRenter("dispute")}} />
                              <span>Dispute Details</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {
                        confirmRenter == "dispute" &&
                        <div className='columnFormInput' style={{marginTop : '15px'}}>
                          <div className='renterInfo'>
                            <label>Description :&nbsp;</label>
                            <textarea className='input' id='descRenter'></textarea>
                          </div>
                        </div>
                      }
                    </>
                  }
                  </div>
                  <div style={{
                    display : "flex",
                    flexDirection : "row",
                    justifyContent : "flex-end",
                    gap : "10px",
                    marginTop : "20px"
                  }}
                  >
                    <Button
                      primary={false}
                      paddingVertical="8px"
                      paddingHorizontal="1px"
                      margin="0px"
                      type="button"
                      className={"signInButtonCustom"}
                      onPressFunction={() => {
                        if(noAddressExist)
                          setNoAddressExist(false)
                        else
                          closeMoveOutPopUp()
                      }}
                    >
                      {noAddressExist ? "Back" : "Cancel"}
                    </Button>
                    <Button
                      primary={true}
                      paddingVertical="8px"
                      paddingHorizontal="1px"
                      margin="0px"
                      className={"signInButtonCustom"}
                    >
                      Next
                    </Button>
                  </div>
                </form>
              }
              {
                moveOutLoading &&
                <div style={{minWidth : 600, minHeight : 250, display: "flex", justifyContent : "center", alignItems : "center"}}>
                  <MiniLoader />
                </div>
              }
            </div>
          </div>
        </PopupWrapper>
      }
    </>
  );
}
