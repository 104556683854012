import { getPaymentMethodForUser } from "../../../services/payment.method.service";
import { Account } from "./ManageAccount";

export const fetchAccounts = (userId, editFunction, onlyPrimary = "all") => {
    return getPaymentMethodForUser(userId, onlyPrimary)
    .then((resp)=> {
      if(resp.status){
          let accounts = [];
          resp.data.forEach((e) => {
              if(e.payment_type == 'account'){
                  accounts.push(
                      <Account
                          payment_type={"Account"}
                          editFunction={editFunction} 
                          data={e}
                          id={e.id} 
                          isPrimary={e.isPrimary} 
                          name={e.detail.accountNickname} 
                          last4={e.detail.accountNumber.substring(e.detail.accountNumber.length - 4)}
                      />
                  )
              }
              else if(e.payment_type == 'card') {
                  accounts.push(
                      <Account 
                          payment_type={"Card"}
                          editFunction={editFunction} 
                          data={e}
                          id={e.id} 
                          isPrimary={e.isPrimary} 
                          name={e.detail.cardOwnerName} 
                          last4={e.detail.cardNumber.substring(e.detail.cardNumber.length - 4)}
                      />
                  )
              }
          })
          return accounts;
      }
      else{
        return [];
      }
    })
}