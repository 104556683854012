import React from "react";
import Wrapper from "../wrapper/Wrapper";
import "./Mid.Card.css";

export default function MidCard({children}) {
  return (
    <Wrapper width="100%"  maxWidth={3000}>
      {children}
    </Wrapper>
  );
}
