import React from "react";
import "./Wrapper.css";
import CustomWrapper from "./CustomWrapper";


export default function SidePopupWrapper({children }) {
  return (
    <div className="sidePopupContainer">
      <CustomWrapper
        width="fit-content"
        maxWidth="32%"
        height="100%"
        borderRadius={0}
        border={0}
        paddingHorizontal={0}
        paddingVertical={0}
        marginHorizontal={0}
        marginVertical={0}
        justifyContent="space-between"
        display="flex"
        flexDirection="column"
      >
        {children}
      </CustomWrapper>
    </div>
  );
}
