import React, { useContext, useEffect, useState } from 'react'
import "./Auth.css"
import Header from '../../components/Header/Header';
import Wrapper from '../../components/wrapper/Wrapper';
import {MdClose, MdMail, MdVisibility} from  "react-icons/md"
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { FormException } from '../../utility/exceptions';
import { generateOTP, loginWithEmail, verifyOTP } from '../../services/auth.service';
import OtpBox from '../../components/OTPBox/OtpBox';
import { getUserDetailsFromGoogle, isLoggedIn, performLogin } from '../../utility/util';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleIcon } from '../../components/icons/Icons';
import { forgotPasswordAPI } from '../../services/auth.service';
import { AppContext } from '../../App';
import Checkbox from '../../components/checkbox/Checkbox';
import { Loader } from '../../components/Loader/Loader';

function Login({title = "", withEmail = false}) {

  const [pageLabel, setPageLabel] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [otpCalled, setOTPCalled] = useState(false);
  const [phNumber, setPhoneNumber] = useState(0)
  const [mountDiv, setMountDiv] = useState(false);
  const [isNumberPolicyAccepted, toggleNumberPolicy] = useState(false);
  const [forgotPasswordCalled, setForgotPasswordCalled] = useState(false);

  const navigate = useNavigate();
  
  useEffect(() => {
    document.title = "Sign In | ando"
    setPageLabel(title)
    if(isLoggedIn()){
      navigate("/")
    }
  }, [title, navigate])

  const getOTP = async (e) => {
    setLoading(true)
    e.preventDefault()
    const phoneNumber = e.target.phone_number.value;

    let messageDiv = document.getElementsByClassName("message").item(0);

    // if(phoneNumber.trim().length !== 10) return FormException(e, "Invalid Phone Number", messageDiv);

    messageDiv.style.display = "none";
    messageDiv.classList.remove("error");

    generateOTP(phoneNumber).then((data) => {
      setLoading(false)
      if(data.status){
        setPhoneNumber(phoneNumber);
        setMountDiv(true);
        setForgotPasswordCalled(false);
        setTimeout(() => {
          setOTPCalled(true);
        }, 300);
      }
      else{
        return FormException(e, data.message, messageDiv);
      }
    }).catch((error) => {
      setLoading(false)
      return FormException(e, error.message, messageDiv);
    })

  }

  const validateOTP = (otp, type, source) =>{
    let messageDiv = document.getElementsByClassName("message").item(0);
    setLoading(true)
    verifyOTP(otp,source,type).then((data) => {
      setLoading(false)
      if(data.status){
        messageDiv.style.display = "none";
        messageDiv.classList.remove("error");
        performLogin(source, type,data.data.token)
        if(data.data.isNewAccount){
          navigate("/account/setup")
        }
        else{
          navigate("/dashboard")
        }
      }
      else{
        return FormException(null, data.message, messageDiv);
      }
    }).catch((error) => {
      setLoading(false)
      return FormException(null, error.message, messageDiv);
    })
  }
  const callForgotPassword = () => {
    setMountDiv(true);
    setOTPCalled(false);
    setTimeout(() => {
      setForgotPasswordCalled(true);
    }, 300);
  }

  const forgotPassword = (e) => {
    setLoading(true)
    e.preventDefault();
    let messageDiv = document.getElementsByClassName("message").item(0);
    let email = e.target.email.value;
    forgotPasswordAPI(email)
    .then((res) => {
      setLoading(false)
      if(res?.status){
        messageDiv.innerHTML = res.message;
        messageDiv.style.display = "block"
        messageDiv.classList.add("success");
      }
      else{
        return FormException(null, res.message, messageDiv);
      }
    })
  }

  const emailLogin = (e) => {
    e.preventDefault();
    setLoading(true)
    let messageDiv = document.getElementsByClassName("message").item(0);
    let email = e.target.email.value;
    let password = e.target.password.value;

    if(password.length < 8){
      setLoading(false)
      return FormException(null, "Minimum password length is 8 characters", messageDiv);
    }

    loginWithEmail(email, password).then((data) => {
      setLoading(false)
      if(data.status){
        messageDiv.style.display = "none";
        messageDiv.classList.remove("error");
        performLogin(email, "email", data?.data?.token)
        if(data.data.setup_completed.includes("Step")){
          navigate("/account/setup")
        }
        else{
          navigate("/dashboard")
        }
      }
      else{
        return FormException(null, data.message, messageDiv);
      }      
    }).catch((error) => {
      setLoading(false)
      return FormException(null, error.message, messageDiv);
    })
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setLoading(false)
      googleLoginProcess(codeResponse)
    },
    onError: (error) => {
      setLoading(false)
      console.log('Login Failed:', error)
    },
    flow : 'implicit'
  })

  const googleLoginProcess = (googleResponse) => {
    setLoading(true)
    let messageDiv = document.getElementsByClassName("message").item(0);
    getUserDetailsFromGoogle(googleResponse).then((data) => {
      loginWithEmail(data.email, null).then((resp) => {
        setLoading(false)
        if(resp.status){
          messageDiv.style.display = "none";
          messageDiv.classList.remove("error");
          performLogin(data.email, "email", resp.data.token)
          if(resp.data.setup_completed.includes("Step")){
            navigate("/account/setup")
          }
          else{
            navigate("/dashboard")
          }
        }
        else{
          return FormException(null, resp.message, messageDiv);
        }
      }).catch((error) => {
        setLoading(false)
        return FormException(null, error.message, messageDiv);
      })

    })
    .catch((error) => {
      setLoading(false)
      FormException(null, "Something went Wrong", messageDiv);
    })
  }

  const toggleInputMask = (inputId) => {
    let inputBox = document.getElementById(inputId);
    switch(inputBox.type){
      case "password" : 
        inputBox.type = "text";
      break;
      case "text" :
        inputBox.type = "password";
      break;
    }
  } 

  return (
    <main>
      <Header />
      <section className='mainPanel'>
        <span>{pageLabel}</span>
        {
          !forgotPasswordCalled && !otpCalled && !withEmail &&
          (
            <div className={(!mountDiv && !otpCalled)?"fadeIn":"fadeOut"}>
              <Wrapper>
                <MdClose onClick={() => {
                  window.location="https://dev.ando.app"
                }} color='rgba(0,0,0,0.35)' size={20} className='closePopup'/>
                <div className='signInTitleWrapper'>
                  <label>Sign In</label>
                  <p>Sign in using one of the below methods</p>
                </div>
      
                <form className='signInForm' onSubmit={getOTP}>
                  <div className='signInInput'>
                    <label>Mobile Number</label>
                    <input type='number' name='phone_number' id="phone_number" placeholder='+1 (123) 456 789' />
                  </div>
                  <Button disable={false}>Send One Time Password</Button>
                  <div className='message'>sample text</div>
                </form>
      
                <div className='hr'>
                  <span>OR</span>
                </div>
      
                <Button primary={false} onPressFunction={googleLogin}>
                  <GoogleIcon size={18} />&nbsp; Sign In with Google
                </Button>
      
                <div className='hr'>
                  <span>OR</span>
                </div>
                
                <Link to="/login/email">
                  <Button primary={false}>
                    <MdMail color='#526035' size={18} />&nbsp; Sign In with Email & Password
                  </Button>
                </Link>
      
                <p className='tncMessage' onClick={() => {window.open("/tnc", "_blank", 'rel=noopener noreferrer')}}>By Signing In to ando, you agree to our Privacy Policies & Terms of Services</p>
      
                <div className="signLinkContainer">New User? <Link to={"/register"} className='loginLinks'>Sign Up Here</Link> </div>
              </Wrapper>
            </div>
          )
        }
        {
          !forgotPasswordCalled && !otpCalled && withEmail &&
          (
            <div className={(!mountDiv && !otpCalled)?"fadeIn":"fadeOut"}>
              <Wrapper>
                <Link to="/login">
                  <MdClose color='rgba(0,0,0,0.35)' size={20} className='closePopup'/>
                </Link>
                <div className='signInTitleWrapper'>
                  <label>Sign In</label>
                  <p>Enter your email address and password</p>
                </div>
      
                <form className='signInForm' onSubmit={emailLogin}>
                  <div className='signInInput'>
                    <label>Email</label>
                    <input type='email' name='email' id="email" placeholder='example@dot.com' required/>
                  </div>
                  <div className='signInInput'>
                    <label>Password</label>
                    <input type='password' name='password' id="password" placeholder='Password' required/>
                    <MdVisibility 
                      className='maskInputContainer'
                      onClick={toggleInputMask.bind(this, "password")}
                      color='rgba(0,0,0,0.25)' 
                      size={18}/>
                  </div>
                  <div className='forgotPasswordLink' onClick={callForgotPassword}>Forgot Password?</div>
                  <Button>Login</Button>
                  <div className='message'>sample text</div>
                </form>
      
                <div className='hr'></div>
      
                <p className='tncMessage' onClick={() => {window.open("/tnc", "_blank", 'rel=noopener noreferrer')}}>By Signing In to ando, you agree to our Privacy Policies & Terms of Services</p>
      
                <div className="signLinkContainer">New User? <Link to={"/register"} className='loginLinks'>Sign Up Here</Link> </div>
              </Wrapper>
            </div>
          )
        }

        {
          otpCalled &&
          (
            <div className={mountDiv && otpCalled?"fadeIn":"fadeOut"}>
              <Wrapper>
                <MdClose color='rgba(0,0,0,0.35)' size={20} className='closePopup'/>
                <div className='signInTitleWrapper'>
                  <label>OTP Verification</label>
                  <p>Enter the  six digit passcode sent to <b>{phNumber}</b></p>
                </div>
                <div className='signInForm'>
                  <OtpBox 
                    length={6}
                    handleValidation={validateOTP}
                    source={phNumber}
                    type={withEmail?"email" : "phone"}
                  />
                </div>
              </Wrapper>
            </div>
          )
        }
        {
          forgotPasswordCalled &&
          (
            <div className={mountDiv && forgotPasswordCalled?"fadeIn":"fadeOut"}>
              <Wrapper>
                <MdClose onClick={() => {setForgotPasswordCalled(false)}} color='rgba(0,0,0,0.35)' size={20} className='closePopup'/>
                <div className='signInTitleWrapper' style={{marginBottom : 20}}>
                  <label>Forgot Password?</label>
                  <p>Don’t sweat it! Just enter your email address and we’ll send you a reset password link.</p>
                </div>
                <form className='signInForm' onSubmit={forgotPassword}>
                  <div className='signInInput'>
                    <label>Email</label>
                    <input type='email' name='email' id="email" placeholder='example@dot.com' required/>
                  </div>
                  <br />
                  <Button>Send Recovery Email</Button>
                  <div className='message'>sample text</div>
                </form>
                <div className='hr'></div>
                <p className='tncMessage' onClick={() => {window.open("/tnc", "_blank", 'rel=noopener noreferrer')}}>By Signing In to ando, you agree to our Privacy Policies & Terms of Services</p>
                <div className="signLinkContainer">New User? <Link to={"/register"} className='loginLinks'>Sign Up Here</Link> </div>
              </Wrapper>
            </div>
          )
        }
      </section>
      {isLoading && <Loader />}
    </main>
  )
}

export default Login