import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import "./Dashboard.css"
import "./../Dashboard.css"

import NotificationBar from "../../../components/NotificationBar/NotificationBar";
import MyProperties from "../../../components/MyProperties/MyProperties";

import MidCard from "../../../components/Mid.Card/Mid.Card";
import SideNavBar from "../../../components/SideNavBar/SideNavBar";
import SVGProgress from "../../../components/svg_progress/SVGProgress";
import { Link, useNavigate } from "react-router-dom";
import { pmTransactionInsite } from "../../../services/payment.method.service";
import MiniGraph from "../../../components/MiniGraph/MiniGraph";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";

export default function PropertyManagerDashboard({userData = null, title = "Welcome back, Anthony" }) {
  const [pageLabel, setPageLabel] = useState("");
  const [currentMonthRent, setCurrentMonthRent] = useState(0);
  const [rentCollected, setRentCollected] = useState(null);
  const [currentMonthOntime, setCurrentMonthOntime] = useState(0);
  const [onTimeRentCollected, setOnTimeRentCollected] = useState(null);
  const [selectedType, setSelectedType] = useState("percent")
  
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Manager | Dashboard | Ando";
    setPageLabel("Welcome back, " + userData.data.first_name);

    pmTransactionInsite(userData.data.id)
    .then((resp) => {
      if(resp?.status){
        let expected = resp?.data?.perMonthExpectedRent ?? 0
        let currentMonth = parseInt(resp?.data?.rentForPastMonths[0]?.amount_paid ?? 0)
        let dataNewRent = Math.round((currentMonth / parseInt(resp?.data?.rentForPastMonths[0]?.expectedRent ?? expected)) * 100)
        setCurrentMonthRent(dataNewRent ? dataNewRent !== "NaN" ? dataNewRent : 0 : 0 ?? 0)
        setRentCollected(resp?.data?.rentForPastMonths?.map((item) => {
          let date = new Date(item?.month_start)
          let mn = date.toLocaleString('default', { month: 'long' }).substring(0,3);
          let yr = date.getFullYear().toString().substring(2,4);
          let per = Math.round((parseInt(item?.amount_paid ?? 0)/parseInt(item?.expectedRent ?? expected))*100);

          return {
            month : mn ? mn == "NaN" ? 0 : mn : 0,
            year : yr ? yr == "NaN" ? 0 : yr : 0,
            percent : per ? per == "NaN" ? 0 : per : 0,
            data : item?.amount_paid ?? 0,
          }
          
        }))

        let expectedOntime = resp?.data?.ontimePayments?.totalMonthlyExpectedTransaction;
        let currentMonthOnTime = parseInt(resp?.data?.ontimePayments?.data[0]?.total_transaction ?? 0)
        let dataNew = Math.round((currentMonthOnTime / parseInt(expectedOntime)) * 100)
        setCurrentMonthOntime(dataNew ? dataNew !== "NaN" ? dataNew : 0 : 0 ?? 0)
        setOnTimeRentCollected(resp?.data?.ontimePayments?.data.map((item) => {
          let date = new Date(item?.transaction_date)
          return {
            month : date.toLocaleString('default', { month: 'long' }).substring(0,3),
            year : date.getFullYear().toString().substring(2,4),
            percent : Math.round((parseInt(item?.total_transaction ?? 0)/parseInt(expectedOntime))*100)
          }
        }))
      }
    })
  }, []);

  return (
    <main style={{height : '100%', display : 'flex', flexDirection : 'column'}}>
      <Header showNotification={true} name={userData?.data?.first_name + " " + userData?.data?.last_name} />
      <section className="mainPanel" style={{flex : 1, gap : '30px', paddingTop : 0, display : "flex", width : '100%', maxWidth : 2500}} >

        <SideNavBar userData={userData} />

        <div className="DashContainer">
            <div className='dashHeader'>
              <div className="pageTitle">{pageLabel}</div>
            </div>
          <div className="rowFormInput rowCustom">
            <div className="columnFormInput" style={{gap : '20px', paddingTop : 15, flex : 1}}>
                <MyProperties userData={userData} activeUser="propertyManager"/>
                {/* Collected Rent Details */}
                <MidCard>
                  <div className="prop-typebox" style={{position : "relative"}}>
                    <label style={{display: "flex", flexDirection : "row", gap : 5, position : "absolute", right : 10, top : -5, fontSize : 10}}>$Dollars <ToggleButton name={"percent"} selected={ selectedType == "percent"} toggleFunction={() => {
                        if(selectedType == "percent")
                          setSelectedType("dollar")
                        else
                          setSelectedType("percent")
                      }}
                    /> %Percent</label>
                    <div>
                      <div className="typeTitle">Rent Collected</div>
                      <div className="typeDescriptionCustom">
                        <span>Rent collected to date for the current month.</span>
                      </div>
                    </div>
                    <div>
                      {" "}
                      <SVGProgress _percentage={currentMonthRent} />
                    </div>
                    <MiniGraph json={rentCollected} selectedType={selectedType}/>
                  </div>
                  <div className="view">
                    <Link onClick={() => {navigate("/property_manager/payment")}}>View Details</Link>
                  </div>
                </MidCard>
                {/* On Time Payment Details */}
                <MidCard>
                  <div className="prop-typebox">
                    <div>
                      <div className="typeTitle">On-Time Payments</div>
                      <div className="typeDescriptionCustom">
                        <span>Rent collected on time for the current month.</span>
                      </div>
                    </div>
                    <div>
                      {" "}
                      <SVGProgress _percentage={currentMonthOntime} />
                    </div>
                    <MiniGraph json={onTimeRentCollected}/>
                  </div>
                  {/* <div className="view">
                    <Link onClick={() => {navigate("/property_manager/payment")}}>View Details</Link>
                  </div> */}
                </MidCard>
            </div>
            <div style={{gap : '20px', paddingTop : 15}}>
              <NotificationBar userData={userData}/>
            </div>
          </div>
        </div>

        
      </section>
    </main>
  );
}
