import React from 'react'
import "./tnc.css"

function Tnc() {
  return (
    <div className='parent'>
        <section>
            <h4>Privacy Policy</h4>
            <h5>Last Updated: 11th April, 2024</h5>
            <div>
                <p>
                    Welcome to Ando ("App", "We", "Us" or "Our"). This Privacy Policy outlines how we
                    collect, use, and protect your personal information. By using our App, you consent to the
                    practices described in this Privacy Policy.
                </p>
                <ol>
                    <li>
                        <div>Information We Collect</div>
                        <ol type='a'>
                            <li>
                                Personal Information: We may collect personal information that you provide directly, such as
                                your name, email address, and any other information you choose to provide.
                            </li>
                            <li>
                                Usage Information: We may collect information about how you interact with the App, including
                                your device information, IP address, and usage patterns.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Use of Information</div>
                        <ol type='a'>
                            <li>
                                We use the collected information to operate, maintain, and improve the App's functionality and user experience.
                            </li>
                            <li>
                                Personal information may be used to communicate with you, respond to inquiries, and provide customer support.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Data Sharing</div>
                        <ol type='a'>
                            <li>
                                We do not share your personal information with third parties unless required by law or as necessary for the operation of the App.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Cookies and Tracking Technologies</div>
                        <ol type='a'>
                            <li>
                                We may use cookies and similar tracking technologies to enhance your experience on the App.
                            </li>
                            <li>
                                You can manage your cookie preferences through your browser settings.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Security</div>
                        <ol type='a'>
                            <li>
                                We implement reasonable security measures to protect your personal information from unauthorized access or disclosure.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Children's Privacy</div>
                        <ol type='a'>
                            <li>
                                The App is not intended for individuals under the age of 16. We do not knowingly collect personal information from children.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Third-Party Links</div>
                        <ol type='a'>
                            <li>
                                The App may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Your Choices</div>
                        <ol type='a'>
                            <li>
                                You can update or delete your personal information by updating from profile section or contacting us at email given below.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Changes to Privacy Policy</div>
                        <ol type='a'>
                            <li>
                                We reserve the right to update or modify this Privacy Policy at any time. The updated policy will be effective upon posting.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Contact Us</div>
                        <ol type='a'>
                            <li>
                                If you have any questions about this Privacy Policy, please contact us at <a href="mailto:customersupport@ando.app">customersupport@ando.app</a>.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>SMS Communications</div>
                        <ol type='a'>
                            <li>
                                <b>Purpose:</b> We may send SMS messages to your registered phone number for verification and security purposes, such as sending One-Time Passwords (OTPs) to authenticate your identity.
                            </li>
                            <li>
                                <b>Consent:</b> By providing your phone number, you consent to receive SMS messages from us for these purposes. If you do not wish to receive such messages, please do not provide your phone number.
                            </li>
                            <li>
                                <b>Data Handling:</b> We will only use your phone number for the purposes mentioned and will not share it with third parties except as necessary to deliver the service.
                            </li>
                            <li>
                                <b>Opt-Out:</b> You can opt-out of receiving SMS messages at any time by emailing us at <a href="mailto:customersupport@ando.app">customersupport@ando.app</a>.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Rental Data Reporting</div>
                        <ol type='a'>
                            <li>
                                <b>Purpose:</b> We may collect and report your rental payment data to credit reporting agencies for the purpose of building or verifying your credit history.
                            </li>
                            <li>
                                <b>Data Collected:</b> The data collected may include your payment amounts, dates, and rental status, as well as any delinquencies or defaults.
                            </li>
                            <li>
                                <b>Data Sharing:</b> By using our App and making rental payments, you consent to us sharing your rental payment data with credit reporting agencies. This information may be used by those agencies to calculate your credit score and for other lawful purposes.
                            </li>
                            <li>
                                <b>Your Rights:</b> You have the right to dispute any inaccurate information reported to the credit agencies by contacting us at <a href="mailto:customersupport@ando.app">customersupport@ando.app</a>. We will work with you and the credit agencies to resolve any discrepancies.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </section>
        <section>
            <h4>Terms and Conditions</h4>
            <h5>Last Updated: 11th April, 2024</h5>
            <div>
                <p>
                    Welcome to Ando ("App", "We", "Us" or "Our"). By accessing and using our App,
                    you agree to comply with and be bound by the following Terms and Conditions ("T&C"). If you
                    disagree with any part of these T&C, please do not use our App.
                </p>
                <ol>
                    <li>
                        <div>Acceptance of Terms</div>
                        <ol type='a'>
                            <li>
                                By using the App, you agree to abide by these T&C and any future updates or modifications. If
                                you do not agree, please discontinue use of the App.
                            </li>
                            <li>
                                Usage Information: We may collect information about how you interact with the App, including
                                your device information, IP address, and usage patterns.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Use of the App</div>
                        <ol type='a'>
                            <li>
                                You must be 16 years or older to use this App.
                            </li>
                            <li>
                                You agree not to use the App for any unlawful or unauthorized purpose and to comply with all applicable laws and regulations.
                            </li>
                            <li>
                                You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Intellectual Property</div>
                        <ol type='a'>
                            <li>
                                All content and materials available on the App, including but not limited to text, graphics,
                                logos, images, and software, are the property of Ando and are protected by
                                intellectual property laws.
                            </li>
                            <li>
                                You may not reproduce, distribute, modify, display, prepare derivative works based on, or use
                                any content from the App without our prior written consent.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Privacy</div>
                        <ol type='a'>
                            <li>
                                Your use of the App is also governed by our Privacy Policy, which is available given above. By using the App, you consent to the practices described in the Privacy Policy.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Disclaimer</div>
                        <ol type='a'>
                            <li>
                                The App is provided on an "as-is" and "as-available" basis without any warranties, expressed or implied.
                            </li>
                            <li>
                                We do not warrant that the App will be error-free, uninterrupted, secure, or accurate.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Limitation of Liability</div>
                        <ol type='a'>
                            <li>
                                To the maximum extent permitted by applicable law, we shall not be liable for any indirect,
                                incidental, special, consequential, or punitive damages, or any loss of profits or revenues,
                                whether incurred directly or indirectly.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Changes to T&C</div>
                        <ol type='a'>
                            <li>
                                We reserve the right to modify or replace these T&C at any time. Your continued use of the App
                                after any changes will constitute acceptance of the updated T&C.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Governing Law</div>
                        <ol type='a'>
                            <li>
                                These T&C shall be governed by and construed in accordance with the laws of [United States of America's Jurisdiction], without regard to its conflict of law principles.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>SMS Messaging</div>
                        <ol type='a'>
                            <li>
                                <b>Consent:</b> By registering your phone number with us, you agree to receive SMS messages from us, including but not limited to, One-Time Passwords (OTPs) for authentication purposes.
                            </li>
                            <li>
                                <b>Opt-Out:</b> If you wish to stop receiving SMS messages, you may opt-out by emailing us at <a href="mailto:customersupport@ando.app">customersupport@ando.app</a>.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <div>Rental Data Reporting</div>
                        <ol type='a'>
                            <li>
                                <b>Consent:</b> By using our App and making rental payments through it, you agree that we may report your rental payment history to credit reporting agencies. This information may include payment amounts, dates, rental status, and any delinquencies or defaults.
                            </li>
                            <li>
                                <b>Purpose:</b> The reporting of rental payment data to credit agencies is intended to help build or verify your credit history.
                            </li>
                            <li>
                                <b>Dispute Resolution:</b> If you believe that any information reported to the credit agencies is inaccurate, you may contact us at <a href="mailto:customersupport@ando.app">customersupport@ando.app</a>. We will assist in resolving any inaccuracies.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </section>
    </div>
  )
}

export default Tnc