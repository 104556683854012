import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header/Header";
import "./Dashboard.css"
import "./../Dashboard.css"
import SideNavBar from "../../../components/SideNavBar/SideNavBar";
import CustomWrapper from "../../../components/wrapper/CustomWrapper";
import { Details } from './DashboardDetails'
import NotificationBar from "../../../components/NotificationBar/NotificationBar";
import MyProperties from "../../../components/MyProperties/MyProperties";

import MidCard from "../../../components/Mid.Card/Mid.Card";
import SVGProgress from "../../../components/svg_progress/SVGProgress";
import { loadRenterDetails } from "../../../services/property.service";
import { isLoggedIn } from "../../../utility/util";
import { renterTransactionInsite } from "../../../services/payment.method.service";
import { Link, useNavigate } from "react-router-dom";

export default function RenterDashboard({ userData = null, title = "Welcome back, Anthony" }) {
  const [currentDashboard,setCurrentDashboard]=useState("renter")
  const [pageLabel, setPageLabel] = useState("");
  const [consecutive, setConsecutive] = useState(0);
  const [transactionData, setTransactionData] = useState(null);
  const [pr, setPr] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Renter | Dashboard | Ando";
    setPageLabel("Welcome back, " + userData.data.first_name);
    if (userData == null) {
      let uInfo = isLoggedIn();
      userData = uInfo;
    }
    renterTransactionInsite(userData.data.id)
    .then((resp) => {
      if(resp?.status){
        setConsecutive(resp?.data?.consecutivePaymentCounts)
        setPr(resp?.data?.onTimePayments)
      }
    })

  }, []);

  return (
    <main style={{height : '100%', display : 'flex', flexDirection : 'column'}}>
      <Header showNotification={true}  name={userData?.data?.first_name + " " + userData?.data?.last_name} />
      <section className="mainPanel" style={{flex : 1, gap : '30px', paddingTop : 0, display : "flex", width : '100%', maxWidth : 2500}}>
        <SideNavBar userData={userData}/>
        { currentDashboard ==="renter" &&
          <div className="DashContainer">
            <div className='dashHeader'>
              <div className="pageTitle">{pageLabel}</div>
            </div>
            <div className="rowFormInput rowCustom">
              <div className="columnFormInput" style={{gap : '20px', paddingTop : 15, flex : 1}}>
                  <MyProperties userData={userData} activeUser="renter" />
                <div className="role-container">
                  <MidCard>
                    <div className="renterTypeTitle ">On-Time Payments</div>
                    <div className="propRenter-typebox"> 
                      <div>
                        <div className="typeDescription">
                          <span>Rent paid on time, over the course of the year</span>
                        </div>
                        <div className="emptySpace"></div>
                      </div>
                      <div>
                        {" "}
                        <SVGProgress _percentage={pr.toFixed(1)} />
                      </div>
                    </div>
                    <div className="view">
                      <Link onClick={() => {navigate("/renter/payment")}}>View Details</Link>
                    </div>
                  </MidCard>
                  <MidCard>
                    <div className="renterTypeTitle ">Consecutive Payments</div>
                    <div className="propRenter-typebox"> 
                      <div>
                        <div className="typeDescription">
                          <span>You’ve reached {consecutive} in a row! Keep it up!</span>
                        </div>
                        <div>
                          <img 
                            src={require("../../../assets/celebration.png")} 
                            className="celebration-icon" />
                        </div>
                      </div>
                      <div>
                        <div className="trophy-container">
                          <img
                            src={require("../../../assets/trophy.png")}
                            className="trophy-icon"
                          />
                          <span className="reachedCount">4</span>
                        </div>
                      </div>
                    </div>
                  </MidCard>
                </div>
                    
              </div>
              <div style={{gap : '20px', paddingTop : 15}}>  
                <NotificationBar userData={userData}/>
              </div>
            </div>
          </div>
        }

        { currentDashboard !== "renter" && 
          <div className="DashContainer">
            <CustomWrapper
              paddingHorizontal={60}
              paddingVertical={50}
              width={"100%"}
              maxWidth={700}
              minHeight={"400px"}
              marginHorizontal={"30px"}
              marginVertical={"30px"}
            >  
              <div className="renterTitle">
                {Details.title}, {userData.data.first_name}!
              </div>
              <div className="renterDescription">
                {Details.description}
              </div>
      
            </CustomWrapper>
          </div>
        }
      </section>
    </main>
  );
}
