import React from 'react'
import "./Custom.css"

function CustomWrapper(
  {
    children, 
    border = "border: 1px solid rgba(0,0,0,0.1)", 
    background="#FFFFFF", 
    borderRadius = 15, 
    marginVertical = "10px", 
    marginHorizontal = "10px",  
    minHeight = "auto", 
    maxHeight = "auto", 
    height = "auto", 
    maxWidth = 1000, 
    width = "min-content", 
    paddingHorizontal = 15, 
    paddingVertical = 15,
    display = "block",
    flexDirection = "column",
    justifyContent = "block",
    overflowY="hidden",
  }) {
  return (
    <div 
      className='customWrapper scroller'
      style={
        {
          display : display,
          flexDirection : flexDirection,
          justifyContent : justifyContent,
          paddingTop : paddingVertical,
          paddingBottom : paddingVertical,
          paddingLeft : paddingHorizontal,
          paddingRight : paddingHorizontal,
          width : width,
          maxWidth : maxWidth,
          height : height,
          minHeight : minHeight,
          maxHeight : maxHeight,
          borderRadius : borderRadius,
          marginTop : marginVertical,
          marginBottom : marginVertical,
          marginLeft : marginHorizontal,
          marginRight : marginHorizontal,
          background : background,
          border: border,
          overflowY:overflowY
        }
      }
    >{children}</div>
  )
}

export default CustomWrapper