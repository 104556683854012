import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import RenterPayment from './renter/RenterPayment';
import PropertyManagerPayment from './property_manager/PropertyManagerPayment';
import { fetchUserDetails } from '../../services/account.service';
import { cacheUser, isLoggedIn } from '../../utility/util';

function Payment() {
    const navigate = useNavigate();
    const searchParams = useParams();
    const [userData, setUserData] = useState({});
    const [initialComp, setInitialComp] = useState(null);
    
    useEffect(() => {
        const userInfo = isLoggedIn();
        if(!userInfo){
            return navigate("/login");
        }
        else{
            if(!userInfo?.data){
                fetchUserDetails(userInfo.source, userInfo.type).then((resp) => {
                    setUserData({
                        data : resp.data,
                        source : userInfo.source,
                        type : userInfo.type
                    });
                    cacheUser(resp.data)
                    if(resp?.data?.setup_completed != "Completed"){
                        return navigate("/account/setup")
                    }
                });
            }
            else{
                setUserData(userInfo);
                if(userInfo?.data?.setup_completed != "Completed"){
                    return navigate("/account/setup")
                }
            }
        }

    }, [navigate]);

    useEffect(() => {
        setInitialComp(searchParams?.action == "manage" ? "ManageAccount" : null)
    }, [searchParams])
    
    if(searchParams.identifier === "renter"){
        return (
            <>
            {
                userData && userData?.data &&
                <RenterPayment userData={userData} initialComponent={initialComp}/>
            }
            </>
        )
    }
    else if(searchParams.identifier === "property_manager"){
        return (
            <>
            {
                userData && userData?.data &&
                <PropertyManagerPayment userData={userData} initialComponent={initialComp}/>
            }
            </>
        )
    }
}

export default Payment