import React from 'react'
import "./Note.css"
import BellIcon from "../../assets/bell_icon.png"
function SmallNote({children}) {
  return (
    <div className='notification smallNot'>
        <img src={BellIcon} />
        <div className='notificationMessage'>{children}</div>
    </div>
  )
}

export default SmallNote