import React, { useContext, useState } from 'react';
import "./SideNavbar.css";
import {MdSettings,MdSwapHoriz,MdBusinessCenter,MdBusiness,MdHistory, MdSpeed} from "react-icons/md";
import {FaStar} from "react-icons/fa"
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { logout } from '../../utility/util';
import Profile from '../profile/Profile';
import PopupWrapper from '../wrapper/PopupWrapper';
import Button from '../Button/Button';
import { openSupportIssue } from '../../services/base.service';
import { AppContext } from '../../App';
import { Loader } from '../Loader/Loader';

export default function SideNavBar({userData = null}) {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [profileActivated , setProfileActivated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isHelpAndSupportClicked, setHelpAndSupport] = useState(false);
  const [isLogoutClicked, toggleLogoutButton] = useState(false);

  const performLogout = () => {
    if(logout()){
      navigate("/login");
    }
  }

  const openSupportTicket = async () => {
    setLoading(true)
    let data = document.getElementsByName("helpInputBox").item(0).value;
    if(data.length > 10){
      let status = await openSupportIssue({message: data})
      setLoading(false)
      if(status == 200){
        appContext.toastRef.current.launch("Support Ticket Raised, we'll contact you soon");
      }
      else{
        appContext.toastRef.current.launch("Something went wrong");
      }
      setHelpAndSupport(false);
    }
  }

  return (
    <>
      <section className='sidenav'>
        <div  className="sidebar">
          {
            userData?.data?.role?.user_role.toLowerCase() === "property manager" &&
            <span style={{display: 'flex',flexDirection: 'column', gap: 10}}>
              <NavLink activeclassname={"active"} to={"/dashboard"} >
                <MdHistory size={20}/> Dashboard
              </NavLink>
              <NavLink activeclassname={"active"} to={"/property_manager/properties"}>
                <MdBusiness size={18}/> Properties
              </NavLink>
              <NavLink activeclassname={"active"} to={"/property_manager/payment"}>
                <MdSwapHoriz size={20}/> Payments
              </NavLink>
            </span>
          }
          {
            userData?.data?.role?.user_role.toLowerCase() === "renter" &&
            <span>
              <NavLink activeclassname={"active"} to={"/dashboard"} >
                <MdHistory size={20}/> Dashboard
              </NavLink>
              <NavLink activeclassname={"active"} to={"/renter/payment"}>
                <MdSwapHoriz size={20}/> Pay Rent
              </NavLink>
              <NavLink activeclassname={"active"} to={"/creditScore"}>
                <MdSpeed size={20}/> Credit Score
              </NavLink >
              <NavLink activeclassname={"active"} to={"/renter/rewards"}>
                <FaStar size={20}/> Rewards
              </NavLink>
            </span>
          }
          <span>
            <Link onClick={() => {setProfileActivated(true)}}>
              <MdBusinessCenter size={18}/>  Profile
            </Link>

            <Link onClick={() => {setHelpAndSupport(true)}}>
              <MdSettings size={18}/> Help/Support
            </Link>

            <Link onClick={() =>{toggleLogoutButton(true)}}>
              <MdHistory size={18}/> Sign Out
            </Link>
          </span> 
        </div>
      </section>
      {
        profileActivated &&
        <Profile
          userData={userData}
          onClose={() =>{setProfileActivated(false)}}/>
      }
      {
        isLogoutClicked &&
        (
            <PopupWrapper zIndex={200} maxWidth={450} onClose={() => {toggleLogoutButton(false)}}>
              <div style={{fontSize : 16, padding : '0px 12px'}}>
                <h4 style={
                  {
                    marginBottom : '5px', 
                    color : '#526035', 
                    fontSize : '18px', 
                    fontWeight : 'bold'
                  }
                }>Sign out?</h4>
                Are you sure you want to sign out?
              </div>
              <div style={{width : 'fit-content', display : 'flex'}}>
                <Button
                  className='minButton'
                  type='button'
                  primary={false}
                  onPressFunction={performLogout}
                >Sign Out</Button>
                <Button
                  className='minButton'
                  type='submit'
                  primary={true}
                  onPressFunction={() => {toggleLogoutButton(false)}}
                >Stay Here</Button>
              </div>
            </PopupWrapper>
        )
      }
      {
        isHelpAndSupportClicked && 
        (
          <PopupWrapper
            maxWidth={580}
            onClose={() => {setHelpAndSupport(false)}}
          >
            <div className='helpbox'>
              <div className='helpboxHeader'>
                <img src={require("../../assets/help.png")} />
                <div className='helpTitle'>
                  <p>Need help or support?</p>
                  <span>Send us an email and we'll get back to you within 48 hours.</span>
                </div>
              </div>
              <textarea name="helpInputBox" placeholder='Insert comments here.'></textarea>
              <Button
                className='minButton'
                type='submit'
                primary={true}
                onPressFunction={openSupportTicket}
              >Submit</Button>
            </div>
          </PopupWrapper>
        )
      }
      {
        isLoading && <Loader />
      }
    </>
  )
}
