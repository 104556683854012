import { ACCOUNT, GENERATE_OTP, USER_DETAILS_FIND_BY_EMAIL, VERIFY_OTP } from "./base.service";

export const generateOTP = async (source, isForNew = false, type = "phone") => {
    const data = await fetch(GENERATE_OTP , {
        body : JSON.stringify({
            source : source,
            type : type,
            isForNew : isForNew
        }),
        headers : {"Content-Type" : "application/json"},
        method : 'POST'
    })
    return data.json();
}

export const verifyOTP = async (OTP, source, type) => {
    const data = await fetch(VERIFY_OTP , {
        body : JSON.stringify({
            source : source,
            type : type,
            otp : OTP
        }),
        headers : {"Content-Type" : "application/json"},
        method : 'POST'
    })
    return data.json();
}

export const loginWithEmail = async (email, password) => {
    const data = await fetch(USER_DETAILS_FIND_BY_EMAIL , {
        body : JSON.stringify({
            email : email,
            password : password != null ? btoa(password) : null,
            loggedInFrom : password != null ? 'email' : 'google'
        }),
        headers : {"Content-Type" : "application/json"},
        method : 'POST'
    })
    return data.json();
}

export const registerWithEmailAndPassword = async (email, password) => {
    const data = await fetch(ACCOUNT + "/new" , {
        body : JSON.stringify({
            email : email,
            password : password != null ? btoa(password) : null,
            loggedInFrom : 'email'
        }),
        headers : {"Content-Type" : "application/json"},
        method : 'POST'
    })
    return data.json();
}

export const forgotPasswordAPI = async (email) => {
    const data = await fetch(ACCOUNT + "/forgotPassword" , {
        body : JSON.stringify({email : email}),
        headers : {"Content-Type" : "application/json"},
        method : 'POST'
    })
    return data.json();
}
export const resetPasswordAPI = async (email, password) => {
    const data = await fetch(ACCOUNT + "/resetPassword" , {
        body : JSON.stringify({email : email, password : btoa(password)}),
        headers : {"Content-Type" : "application/json"},
        method : 'POST'
    })
    return data.json();
}

export const fetcher = async (
    url,
    method,
    body,
    contentType = "application/json"
) => {
    let token = sessionStorage.getItem("token");
    if(!token){
        console.error("Invalid Token");
        localStorage.clear();
        sessionStorage.clear();
        document.location.reload()
        return;
    }

    const headers = {
        "Content-Type" : contentType,
        "Authorization" : `Bearer ${token}`
    }
    const options = {
        body : null,
        method : method,
        headers : headers
    }
    if(contentType === "application/json" && body){
        options.body = JSON.stringify(body)
    }
    else if(contentType === "multipart/form-data" && body instanceof FormData){
        if(options.headers){
            delete options.headers["Content-Type"];
        }
        options.body = body;
    }

    let response = (await fetch(`${url}`, options))

    if(response?.status === 401 || response?.status === 403){
        console.log("Session Expired");
        localStorage.clear();
        sessionStorage.clear();
        document.location.reload()
        return
    }
    return response
    // let resp = response.text()
    // try{        
    // }
    // catch{
    //     if(response?.status < 400){
    //         return await resp;
    //     }
    //     throw new Error(await resp);
    // }
}