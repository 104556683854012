import React, { useRef, useState } from 'react'
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Home from './screens/Home';
import Login from './screens/auth/Login';
import Register from './screens/auth/Register';
import { loginLoader } from './utility/util';
import AccountSetup from './screens/account/setup/AccountSetup';
import Dashboard from './screens/dashboard/Dashboard';
import Payment from './screens/payment/Payment';
import Property from './screens/property_manager/Property';
import Maintanence from './screens/maintenence/Maintenence';
import Tnc from './screens/tnc/Tnc';
import ResetPassword from './screens/auth/ResetPassword';
import Toast from './components/toast/Toast';


export const AppContext = React.createContext({
  toastRef : null
});

function App() {
  const contextValue = {
    toastRef : useRef()
  }

  const router = createBrowserRouter([
    {
      path : "/",
      element : <Home />,
      loader : loginLoader
    },
    {
      path : "/login",
      element : <Login />
    },
    {
      path : "/login/email",
      element : <Login withEmail={true} />
    },
    {
      path : "/register",
      element : <Register />
    },
    {
      path : "/register/email",
      element : <Register withEmail={true} />
    },
    {
      path : "/account/setup",
      element : <AccountSetup />
    },
    {
      path : "*",
      element : (
        <div 
        style={
          {
            display:'flex',
            flexDirection : 'column', 
            height : '100%', 
            justifyContent : 'center', 
            alignItems : 'center'
          }
        }>
          <h1>404 - Page Not Found</h1>
          <p>Copyright by - Ando</p>
        </div>
      )
    },
    {
      path : "/dashboard/:identifier",
      element : <Dashboard />,
    },
    {
      path : "/dashboard",
      element : <Dashboard />,
    },
    {
      path : "/:identifier/payment",
      element : <Payment />
    },
    {
      path : "/:identifier/payment/account/:action",
      element : <Payment />
    },
    {
      path : "/property_manager/properties",
      element : <Property />
    },
    {
      path : "/property_manager/properties/:action",
      element : <Property />
    },
    {
      path : "/creditScore",
      element : <Maintanence title={"Credit Score"} />
    },
    {
      path : "/renter/rewards",
      element : <Maintanence title={"Rewards"} />
    },
    {
      path : "/tnc",
      element : <Tnc/>
    },
    {
      path : "/reset/:hash",
      element : <ResetPassword />
    }
  ]);

  return (
    <AppContext.Provider value={contextValue} >
      <RouterProvider router={router} />
      <Toast launchRef={contextValue.toastRef}/>
    </AppContext.Provider>
  )
}

export default App