import React from 'react'
import "./ToggleButton.css"

function ToggleButton({selected, toggleFunction, name}) {

    const isSelected = selected;

    return (
        <span onClick={toggleFunction} className={'toggleContainer ' + (isSelected ? "selectedContainer" : "unSelectedContainer")}>
            <div></div>
            <input type='hidden' name={name} value={isSelected}/>
        </span>
    )
}

export default ToggleButton