import React from 'react'

function MiniGraph({json, selectedType}) {
    const loadData = () => {
        return json?.map((item) => {
            return (
                <div className="miniGraphBar">
                  <div className="miniGraphBarText">{selectedType == "dollar"  ? `$${item?.data}` : `${item?.percent?? 0}%`}</div>
                  <div className="miniGraphBarFill" style={{height : `${item?.percent < 5 ? 5 : item?.percent > 100 ? 100 : item?.percent}%`}}></div>
                  <div className="miniGraphBarTextBottom">{item?.month}'{item?.year}</div>
                </div>
            )
        })
    }

  return (
    <div className="miniGraph">{loadData()}</div>
  )
}

export default MiniGraph