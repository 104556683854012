import { fetcher } from "./auth.service";
import { CREATE_NEW_PROPERTY, GET_ALL_PROPERTIES, GET_DUES_FOR_PM, GET_PROPERTY_UNITS_FOR_RENTER, GET_RENT_DUES } from "./base.service";


export const createNewProperty = async (input) => {
    const data = await fetcher(CREATE_NEW_PROPERTY , 
        'POST',
        input,
        "application/json"
    )
    return data.json();
}

export const updatePropertyDetails = async (input, propertyId) => {
    const data = await fetcher(GET_ALL_PROPERTIES + "/update/" + propertyId , 
        'PATCH',
        input,
        "application/json"
    )
    return data.json();
}

export const loadRenterDetails = async (phone, email, needMyProperty = false) => {
    const data = await fetcher(GET_PROPERTY_UNITS_FOR_RENTER , 
        'POST',
        {phone : phone, email : email, confirmed : needMyProperty},
        "application/json"
    )

    return data.json();
}
export const getRenterDues = async (user) => {
    const data = await fetcher(GET_RENT_DUES + `/${user}`, 'GET')
    return data.json();
}
export const getDuesForPM = async (user) => {
    const data = await fetcher(GET_DUES_FOR_PM + `/${user}`, 'GET')
    return data.json();
}

export const searchPropertyForRenter = async (phone, email, address) => {
    const data = await fetcher(GET_PROPERTY_UNITS_FOR_RENTER + "/info" , 
        'POST',
        {phone : phone, email : email, address},
        "application/json"
    )

    return data.json();
}

export const isAddressAvailable = async (address) => {
    const data = await fetcher(
        GET_ALL_PROPERTIES + "/address",
        "POST",
        {address : address},
        "application/json"
    );

    return data.json();
}


export const raiseDispute = async (userId, disputeDescription, propertyAddress, unit) => {
    const data = await fetcher(
        GET_ALL_PROPERTIES + "/dispute/raise",
        "POST",
        {
            userId : userId,
            disputeDescription : disputeDescription,
            propertyAddress : propertyAddress,
            unit : unit
        },
        "application/json"
    )

    return data.json();
}

export const confirmRenterProperty = async (userId, propertyAddress, unit) => {
    const data = await fetcher(
        GET_ALL_PROPERTIES + "/confirm/property",
        "POST",
        {
            userId : userId,
            propertyAddress : propertyAddress,
            unit : unit
        },
        "application/json"
    )

    return data.json();
}

export const getAllProperties = async (id) =>{
    const data = await fetcher(GET_ALL_PROPERTIES + "/all/user/" + id , 
        'GET'
    )
    return data.json();
}

export const moveOutRenter = async (body) =>{
    const data = await fetcher(GET_ALL_PROPERTIES + "/moveout/user/", 
        "POST",
        body
    )
    return data.json();
}

export const deleteProperty = async (id) =>{
    const data = await fetcher(GET_ALL_PROPERTIES + "/" + id , 
        'DELETE'
    )
    return data.json();
}