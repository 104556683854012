import React, { useContext, useEffect, useState } from 'react'
import SidePopupWrapper from '../wrapper/SidePopupWrapper'
import { MdCheck, MdClose, MdEdit } from 'react-icons/md'
import "./Profile.css"
import { fetchAccounts } from '../payment/common/fetchAccounts';
import { useNavigate } from 'react-router-dom';
import { accountSetup } from '../../services/account.service';
import { cacheUser, isLoggedIn } from '../../utility/util';
import { FormException } from '../../utility/exceptions';
import { AppContext } from '../../App';
import { MiniLoader } from '../Loader/Loader';

function Profile({onClose=null, userData=null}) {

    const appContext = useContext(AppContext);

    const [userInfo, setUserInfo] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [abbrName, setAbbrName] = useState(null);
    const [accountList, setAccountList] = useState([]);

    const [editPersonalDetails, toggleEditPersonalDetails] = useState(false);
    const [editContactDetails, toggleEditContactDetails] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        setUserInfo(userData);
        let name = userData?.data?.first_name + " " + userData?.data?.last_name;
        if(name.length > 0){
            let d = name.split(" ");
            setAbbrName(d[0].charAt(0) + "" + d[1].charAt(0))
        }
        setLoading(true)
        fetchAccounts(userData?.data?.id, null, "primary")
        .then((accounts) => {
            setLoading(false)
            setAccountList([...accounts]);
        })
    }, [])

    const paymentPageNavigate = () => {
        if(!isLoading){
            onClose();
            navigate(`/${userData?.data?.role?.user_role.replaceAll(" ", "_").toLowerCase()}/payment/account/manage`)
        }
    }

    const savePersonalDetails = () => {
        setLoading(true)
        accountSetup(userInfo.data.id, {
            first_name : userInfo.data.first_name,
            last_name : userInfo.data.last_name
        }).then((res) => {
            setLoading(false)
            if(res.status){
                cacheUser(userInfo.data);
                appContext.toastRef.current.launch("Details Updated");
            }
            else{
                setUserInfo(isLoggedIn());
                appContext.toastRef.current.launch(res.message);
            }
            toggleEditPersonalDetails(false)
        })
    }

    const saveContactDetails = () => {
        
        let messageDiv = document.getElementsByClassName("message").item(0);
        let phoneLength = userInfo.data.phone_number.length;
        if( phoneLength > 0 && (phoneLength > 10 || phoneLength < 10)){
            return FormException(null, "Mobile number length should be 10", messageDiv);
        }
        accountSetup(userInfo.data.id, {
            email : userInfo.data.email,
            phone_number : userInfo.data.phone_number,
        }).then((res) => {
            if(res.status){
                cacheUser(userInfo.data);
                appContext.toastRef.current.launch("Details Updated");
            }
            else{
                setUserInfo(isLoggedIn());
                appContext.toastRef.current.launch(res.message);
            }
            toggleEditContactDetails(false)
            
        messageDiv.style.display = "none";
        messageDiv.classList.remove("error");
        })
    }

    const handleInputChange = (e) => {
        let tempUserData = Object.create(userInfo);
        if(e.target.name == "phone_number"){
            const onlyNumberRegex = /^\d+$/;
            if(onlyNumberRegex.test(e.target.value)){
                tempUserData.data[e.target.name] = e.target.value;
                tempUserData.data["phone_number"] =  tempUserData.data["phone_number"].substring(0, 10)
            }
        }
        else{
            tempUserData.data[e.target.name] = e.target.value;
        }
        setUserInfo(tempUserData);
    }

    useEffect(() => {
        if(!editContactDetails){
            let messageDiv = document.getElementsByClassName("message").item(0);
            setUserInfo(isLoggedIn());
            messageDiv.style.display = "none";
            messageDiv.classList.remove("error");        
        }
    }, [editContactDetails])

    useEffect(() => {
        if(!editPersonalDetails){
            setUserInfo(isLoggedIn());
        }
    }, [editPersonalDetails])

    return (
        <SidePopupWrapper>
            <div className="paymentHeader">
                <MdClose
                    onClick={onClose}
                    color="white"
                    size={20}
                    className="closePopup"
                />
                <label>My Profile</label>
            </div>
            <div className="paymentBody" style={{flex : 1}}>
                <div className='profileContainer'>
                    <div className='profileContainerHeader'>
                        <div className='pCTitle'>Personal Details</div>
                        {
                            !editPersonalDetails &&
                            <div className='pCEdit' onClick={() => {toggleEditPersonalDetails(true)}}>
                                <MdEdit color='rgba(0,0,0,0.65)' size={20}/>
                                Edit
                            </div>
                        }
                        {
                            editPersonalDetails &&
                            <React.Fragment>
                                <div className='pCEdit' onClick={savePersonalDetails}>
                                    <MdCheck color='rgba(0,0,0,0.65)' size={20}/>
                                    Save
                                </div>
                                <div className='pCEdit' onClick={() => {toggleEditPersonalDetails(false)}}>
                                    <MdClose color='rgba(0,0,0,0.65)' size={20}/>
                                    Cancel
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    {
                        !editPersonalDetails &&
                        <div className='profileMainContainer rowC'>
                            <div className="ellipse">{abbrName}</div>
                            <div className='profileItem'>
                                <div className='profileItemTitle'>Name</div>
                                <div className='profileItemHeading'>{`${userInfo?.data?.first_name} ${userInfo?.data?.last_name}`}</div>
                                <div className='profileItemSubheading'>{`${userInfo?.data?.role?.user_role}`}</div>
                            </div>
                        </div>
                    }
                    {
                        editPersonalDetails &&
                        <div className='profileMainContainer rowC'>
                            <div className='profileItem'>
                                <div className='profileItemTitle'>First Name</div>
                                <div className='profileItemHeading unbold'>
                                    <input type='text' name="first_name" value={`${userInfo?.data?.first_name}`} onChange={handleInputChange}/>
                                </div>
                            </div>
                            <div className='profileItem'>
                                <div className='profileItemTitle'>Last Name</div>
                                <div className='profileItemHeading unbold'>
                                    <input type='text' name="last_name" value={`${userInfo?.data?.last_name}`} onChange={handleInputChange}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='hr'></div>
                <div className='profileContainer'>
                    <div className='profileContainerHeader'>
                        <div className='pCTitle'>Contact Details</div>
                        {
                            !editContactDetails &&
                            <div className='pCEdit' onClick={() => {toggleEditContactDetails(true)}}>
                                <MdEdit color='rgba(0,0,0,0.65)' size={20}/>
                                Edit
                            </div>
                        }
                        {
                            editContactDetails &&
                            <React.Fragment>
                                <div className='pCEdit' onClick={saveContactDetails}>
                                    <MdCheck color='rgba(0,0,0,0.65)' size={20}/>
                                    Save
                                </div>
                                <div className='pCEdit' onClick={() => {toggleEditContactDetails(false)}}>
                                    <MdClose color='rgba(0,0,0,0.65)' size={20}/>
                                    Cancel
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className='profileMainContainer columnC'>
                        {
                            !editContactDetails &&
                            <React.Fragment>
                                <div className='profileItem'>
                                    <div className='profileItemTitle'>Email Address</div>
                                    <div className='profileItemHeading unbold'>{`${userInfo?.data?.email}`}</div>
                                </div>
                                <div className='profileItem'>
                                    <div className='profileItemTitle'>Mobile Number</div>
                                    <div className='profileItemHeading unbold'>{`${userInfo?.data?.phone_number}`}</div>
                                </div>
                            </React.Fragment>
                        }

                        {
                            editContactDetails &&
                            <React.Fragment>
                                <div className='profileItem'>
                                    <div className='profileItemTitle'>Email Address</div>
                                    <div className='profileItemHeading unbold'>
                                        <input type='email' name="email" value={`${userInfo?.data?.email}`} onChange={handleInputChange} disabled={userInfo?.data?.phone_number.length < 5 && userInfo?.data?.email.length > 5}/>
                                    </div>
                                </div>
                                <div className='profileItem'>
                                    <div className='profileItemTitle'>Mobile Number</div>
                                    <div className='profileItemHeading unbold'>
                                        <input type='text' name="phone_number" value={`${userInfo?.data?.phone_number}`} onChange={handleInputChange} disabled={userInfo?.data?.email.length < 5 && userInfo?.data?.phone_number.length > 5}/>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>                
                    <div className='message'>sample text</div>
                </div>
                <div className='hr'></div>
                <div className='profileContainer'>
                    <div className='profileContainerHeader'>
                        <div className='pCTitle'>Payment Details</div>
                        <div className='pCEdit' onClick={paymentPageNavigate}>
                            <MdEdit color='rgba(0,0,0,0.65)' size={20}/>
                            Edit
                        </div>
                    </div>
                    <div className='profileMainContainer columnC'>
                        {isLoading &&
                            <div style={{width : '100%', textAlign : 'center'}}>
                                <MiniLoader />
                            </div>
                        }
                        {!isLoading && accountList}
                    </div>
                </div>
            </div>
            <div className="paymentFooter"></div>
        </SidePopupWrapper>
    )
}

export default Profile