import React, { useEffect, useState } from 'react'

function SVGProgress({_percentage=65 }) {
    const [sqSize, setSqSize] = useState(100);
    const [strokeWidth, setStrokeWidth] = useState(15);
    const [percentage, setPercentage] = useState(65);
    const [viewBox, setviewBox] = useState(null);
    const [dashArray, setdashArray] = useState("");
    const [dashOffset, setdashOffset] = useState("");
    const [radius, setradius] = useState(2);

    useEffect(() =>{
        setPercentage(_percentage > 100 ? 100 : _percentage);
        // Size of the enclosing square
    
        // SVG centers the stroke width on the radius, subtract out so circle fits in square
        setradius((sqSize - strokeWidth) / 2);
        // Enclose cicle in a circumscribing square
        setviewBox(`0 0 ${sqSize} ${sqSize}`);
        // Arc length at 100% coverage is the circle circumference
        setdashArray(radius * Math.PI * 2);
        // Scale 100% coverage overlay with the actual percent
        setdashOffset(dashArray - (dashArray * percentage) / 100);
    })

  return (
    <svg width={sqSize} height={sqSize} viewBox={viewBox}>
      <circle
        className="circle-background"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius - 2}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className="circle-progress"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius - 2}
        strokeWidth={`${strokeWidth - 3}px`}
        // Start progress marker at 12 O'Clock
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
        }}
      />
      <text
        className="circle-text"
        x="50%"
        y="50%"
        dy=".2em"
        textAnchor="middle"
      >
        {`${_percentage}%`}
      </text>
    </svg>
  )
}

export default SVGProgress