import React from 'react'
import "./Wrapper.css"
function Wrapper({children, width="500px", maxWidth = 1000, flex = 0, height = 'auto'}) {
  return (
    <div className='wrapperContainer' 
      style={flex == 0? ({width : width,maxWidth : maxWidth, height : height}) : {flex : flex,maxWidth : maxWidth, height : height}}>
        {children}
    </div>
  )
}

export default Wrapper;