import React, { useEffect, useState } from 'react'
import "../Payment.css"
import Header from "../../../components/Header/Header";
import SideNavBar from "../../../components/SideNavBar/SideNavBar";
import PaymentMethod from '../../../components/payment/renter/PaymentMethod';
import PaymentDashboard from '../../../components/payment/renter/PaymentDashboard';
import Button from '../../../components/Button/Button';
import ManageAccount from '../../../components/payment/common/ManageAccount';
import { getPaymentMethodForUser } from '../../../services/payment.method.service';
import { isLoggedIn } from '../../../utility/util';
import { useNavigate } from 'react-router-dom';
function RenterPayment({userData = null, initialComponent = null}) {

  const [isCallGoing, setCallGoing] = useState(false);

  const [renterPaymentComponent, setRenterPaymentComponent] = useState("PaymentDashboard");
  // const [renterPaymentComponent, setRenterPaymentComponent] = useState("ManageAccount");
  const [editAccountData, setEditAccountData] = useState(null);
  const [paymentAccounts, setPaymentAccounts] = useState(null);
  const navigate = useNavigate();
  useEffect(()=> {
    let uInfo = isLoggedIn();
    if (userData == null) {
      userData = uInfo; 
    }
    if(!isCallGoing){
      setCallGoing(true);
      getPaymentMethodForUser(uInfo?.data?.id).then((resp) => {
        setCallGoing(false);
        if (!resp.status) {
          setRenterPaymentComponent("PaymentMethod");
        }
        else{
          setPaymentAccounts(resp.data);
        }
      }).catch((e) => {
        setCallGoing(false);
      });
    }

  }, []);
  useEffect(() => {    
    if(initialComponent != null && renterPaymentComponent != initialComponent){
      setRenterPaymentComponent(initialComponent)
    }

  }, [initialComponent])

  useEffect(() => {
    if(!isCallGoing){
      setCallGoing(true);
      getPaymentMethodForUser(userData?.data?.id,"primary").then((resp) => {
        setCallGoing(false);
        if (!resp.status) {
          setRenterPaymentComponent("PaymentMethod");
        }
        else{
          setPaymentAccounts(resp.data);
        }
      }).catch((e) => {
        setCallGoing(false);
      });
    }
    
  }, [renterPaymentComponent])

  const editFunction = (data) => {
    setEditAccountData(data);
    setRenterPaymentComponent("EditAccount")
  }

  return (  
    <main style={{height : '100%', display : 'flex', flexDirection : 'column'}}>
        <Header showNotification={true}  name={userData?.data?.first_name + " " + userData?.data?.last_name} />
        <section className="mainPanel" style={{flex : 1, gap : '30px', paddingTop : 0, display : "flex", width : '100%', maxWidth : 2500}} >
            <SideNavBar userData={userData}/>
            <div className="DashContainer">
              <div className='dashHeader'>
                <div className="pageTitle">Payment Details</div>
                {
                  renterPaymentComponent === "PaymentDashboard" &&
                  <Button 
                    className='minButton smallBtn'
                    primary={false}
                    onPressFunction={() => {setRenterPaymentComponent("ManageAccount")}}
                  >Manage Accounts</Button>
                }
              </div>
              {
                renterPaymentComponent === "PaymentMethod" &&
                <PaymentMethod 
                  pageNavigator={setRenterPaymentComponent} 
                  userData={userData} 
                  title='Setup a Payment method to start paying rent.'
                />
              }
              {
                renterPaymentComponent === "PaymentDashboard" &&
                <PaymentDashboard userData={userData} paymentAccounts={paymentAccounts}/>
              }
              {
                renterPaymentComponent === "ManageAccount" &&
                <ManageAccount
                  goBack={() => {setRenterPaymentComponent("PaymentDashboard");navigate("/renter/payment/")}}
                  editFunction={editFunction}
                  userData={userData} 
                  onAddNew={() => {setRenterPaymentComponent("AddNewAccount")}}
                />
              }
              {
                renterPaymentComponent === "AddNewAccount" &&
                <PaymentMethod 
                  pageNavigator={setRenterPaymentComponent} 
                  userData={userData} 
                  title='Link new account to pay rent'
                  isNew={true} 
                  edit={true}
                />
              }
              {
                renterPaymentComponent === "EditAccount" &&
                <PaymentMethod 
                  pageNavigator={setRenterPaymentComponent} 
                  userData={userData} 
                  title='Edit PaymentDetails'
                  isNew={false} 
                  edit={true}
                  data={editAccountData}
                />
              }
            </div>
        </section>
    </main>
  )
}

export default RenterPayment