import { fetcher } from "./auth.service";
import { CREATE_NEW_PAYMENT_METHOD, CREATE_NEW_TRANSACTION, LIST_ALL_TRANSATION_FOR_PROPERTY_MANAGER, LIST_ALL_TRANSATION_FOR_USERS, TRANSACTION_INSITE_PM, TRANSACTION_INSITE_RENTER, UPDATE_PAYMENT_METHOD } from "./base.service";

export const updatePaymentMethod = async (id, paymentDetail) => {
    const data = await fetcher(
        UPDATE_PAYMENT_METHOD + "/" + id,
        "PATCH",
        paymentDetail,
        "application/json"
    )

    return data.json();
}

export const addPaymentMethod = async (paymentDetail) => {
    const data = await fetcher(
        CREATE_NEW_PAYMENT_METHOD ,
        "POST",
        paymentDetail,
        "application/json"
    )

    return data.json();
}

export const getPaymentMethodForUser = async (id, primary) => {
    const data = await fetcher(
        CREATE_NEW_PAYMENT_METHOD + "/user/" + id + (primary == "all" ? "" : "/primary"),
        'GET'
    )
    return data.json();
}
export const deletePaymentMethod = async (id) => {
    const data = await fetcher(CREATE_NEW_PAYMENT_METHOD + "/" + id,
        'DELETE'
    )
    return data.json();
}

export const getAllTrancationsForUser = async (id) => {
    const data = await fetcher(LIST_ALL_TRANSATION_FOR_USERS + "/" + id , 
        'GET'
    )
    return data.json();
}

export const renterTransactionInsite = async (id) => {
    const data = await fetcher(TRANSACTION_INSITE_RENTER + "/" + id ,
        'GET'
    )
    return data.json();
}

export const pmTransactionInsite = async (id) => {
    const data = await fetcher(TRANSACTION_INSITE_PM + "/" + id , 'GET')
    return data.json();
}

export const createNewTransactionForUser = async (input) => {
    const data = await fetcher(
        CREATE_NEW_TRANSACTION ,
        "POST",
        input,
        "application/json"
    )
    return data.json();    
}


export const getAllTrancationsForPropertyManager = async (id, duration) => {
    const data = await fetcher(LIST_ALL_TRANSATION_FOR_PROPERTY_MANAGER + `/${id}/${duration}`, 
        'GET'
    )
    return data.json();
}