import React, { memo, useContext, useEffect, useState } from 'react'
import { MdArrowRight, MdClose, MdDelete, MdOutlineEdit, MdSearch } from 'react-icons/md';
import { FormException } from '../../../utility/exceptions';
import { deleteProperty, isAddressAvailable, updatePropertyDetails } from '../../../services/property.service';
import { addressSearch, isLoggedIn } from '../../../utility/util';
import HighlighterButton from '../../Button/HighlighterButton';
import Button from '../../Button/Button';
import CustomWrapper from '../../wrapper/CustomWrapper';
import PropertyManagerSetup from './EditPropertySetup/PropertyManagerSetup';
import PopupWrapper from '../../wrapper/PopupWrapper';
import { AppContext } from '../../../App';
import { openSupportIssue } from '../../../services/base.service';
import { Loader } from '../../Loader/Loader';

const EditProperty = memo(function EditProperty({userData=null, pageChange, propertyJson}) {
    const [isLoading, setLoading] = useState(false);
    const [propertySetupType, setPropertySetupType] = useState("singleUnit");
    const [propertyDetailsList, setPropertyDetailsList] = useState([]);

    const appContext = useContext(AppContext);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedAddressPlaceID, setSelectedAddressPlaceID] = useState(null);
    const [tempPropertyType, setTempPropertyType] = useState("")
    const [propertyTypeChangeModal, setPropertyTypeChangeModal] = useState(false);
    
    const [isDeleteCalled, setIsDeleteCalled] = useState(false);

    const deletePropertyConfirm = (called) => {
      setIsDeleteCalled(called);
    }

    const delProperty = () => {
      setLoading(true)
      deleteProperty(propertyJson.id)
      .then((res) => {
        setLoading(false)
        if(res.status){
          pageChange("ManageProperty")
        }
      })
    }

    const openSupportTicket = async () => {
      let data = document.getElementsByName("helpInputBox").item(0).value;
      if(data.length > 10){
        let status = await openSupportIssue({message: data})
        if(status == 200){
          appContext.toastRef.current.launch("Support Ticket Raised, we'll contact you soon");
        }
        else{
          appContext.toastRef.current.launch("Something went wrong");
        }
        setPropertyTypeChangeModal(false);
      }
    }
  

    const addPropertyUnit = () => {
        let currentPropertyList = propertyDetailsList;
        currentPropertyList.push(<PropertyManagerSetup listName={"propertyListRegister"} defaultClose={true} />)
        setPropertyDetailsList([...currentPropertyList]);
    }

    const toggleAccordian = (accordianIndex) => {
      const element = document.getElementsByClassName("accordianBox")[accordianIndex];
      // let accordianTitle = element.getElementsByClassName("accordianTitle")[0]
      let divToActUpon = element.getElementsByClassName("accordianBoxContainer")[0].getElementsByClassName("propertyDiv")[0];
      if(divToActUpon.style.display == "none"){
        divToActUpon.style.display = "block"
      }
      else{
        divToActUpon.style.display = "none"
      }
    }
  
    const accordianViewContainer = (propertyList) => {
      let tempView = [];
      let nullPointer = 0;
      propertyList.forEach((propertyView, index) => {
        if(propertyView === null)
          nullPointer++;

        if(propertyView){
          tempView.push(
            <div className='accordianBox' key={index} id={"propertyAccordian-" + (index - nullPointer)}>
              <span className="accordianTitle" onClick={toggleAccordian.bind(this, (index - nullPointer))}>
                <MdArrowRight size={20} color='rgba(0,0,0,0.25)' />
                <label>Unit #{index + 1}</label>
              </span>
              <MdClose size={18} color='rgba(0,0,0,0.35)' className='removeUnit' onClick={removePropertyUnit.bind(this, index)} />
              <div className='accordianBoxContainer'>
                {propertyView}
              </div>
            </div>
          )
        }
      })
      return tempView;
    }
  
    const removePropertyUnit = (index) => {
      let currentPropertyList = propertyDetailsList;
      if(currentPropertyList.length > 1){
        let newPropertyList = currentPropertyList.map((e, i) => {
          if(i != index)
            return e;
          else
            return null
        });
        setPropertyDetailsList([...newPropertyList]);
      }
    }
  

    const editSearchAddress = () => {
        setSelectedAddress(null);
        setSelectedAddressPlaceID(null);
      }   


    const handlePropertySubmit = (e) => {
        e.preventDefault();
        setLoading(true)
    
        let messageDiv = document.getElementsByClassName("message").item(0);
    
        if(selectedAddress == null){
          setLoading(false)
          return new FormException(null, "Please Select an Address from dropdown", messageDiv)
        }
    
        const unitLength = propertyDetailsList.length == 0 ? 1 : propertyDetailsList.filter((e) => e!=null).length ;
    
        let propertyDetail = {
          userId : isLoggedIn().data.id,
          type : propertySetupType,
          address : selectedAddress,
          placeID : selectedAddressPlaceID,
          units : []
        }
        
        for(let j = 0; j < unitLength; j++){
          let unit = {
            id : (unitLength > 1) ? e.target.propertyUnitId[j].value : e.target.propertyUnitId.value,
            propertyName : (unitLength > 1) ? e.target.propertyName[j].value : e.target.propertyName.value,
            renterOccupied : "false",
            leaseFrom : "",
            leaseTo : "",
            rentalPrice : (unitLength > 1) ? e.target.rentalPrice[j].value : e.target.rentalPrice.value,
            // partialPayment : (unitLength > 1) ? e.target.partialPayment[j].value : e.target.partialPayment.value,
            penalty : (unitLength > 1) ? e.target.penalty[j].value : e.target.penalty.value,
            partialPayment : false,
            penaltyPrice : (((unitLength > 1) ? e.target.penalty[j].value : e.target.penalty.value)
            == "true" ) ? (unitLength > 1) ? e.target?.penaltyPrice[j]?.value : e.target?.penaltyPrice?.value : "",
            renterDetails : {
              confirmed : (unitLength > 1) ? (e.target.renterConfirmed[j].value == "true" ? true : false) : (e.target.renterConfirmed.value == "true" ? true : false),
              first_name : (unitLength > 1) ? e.target.first_name[j].value : e.target.first_name.value,
              last_name : (unitLength > 1) ? e.target.last_name[j].value : e.target.last_name.value,
              email : (unitLength > 1) ? e.target.email[j].value : e.target.email.value,
              phone : (unitLength > 1) ? e.target.phone[j].value : e.target.phone.value,
            }
          }
          propertyDetail.units.push(unit);
        }
        updatePropertyDetails(propertyDetail, propertyJson.id).then((resp) => {
          setLoading(false)
          if(resp.status){
            setTimeout(() => {
              pageChange("ManageProperty")
            }, 100)
          }
          else{
            return FormException(e, "Something Went Wrong", messageDiv);
          }
        })
        .catch((error) => {
          setLoading(false)
          return FormException(e, error.message, messageDiv);
        })
      }

      const handleSearchChange = (e) => {
        let UI_LIST = document.getElementById("mapSearchDataList");
        if(e.target.value.trim().length > 2){
          addressSearch(e.target.value)
          .then((response) => {
            if(response.length > 0){
              UI_LIST.innerHTML = null;
              response.forEach((element) => {
                var li = document.createElement('li');
                let displayAddr = element.formatted_address
                if(!element.formatted_address.includes(element.name)){
                  displayAddr = `${element.formatted_address}`
                }
                li.appendChild(document.createTextNode(`${displayAddr}`));
                
                li.onclick = handlePMSearchItemSelect.bind(this, `${element.formatted_address}`, `${element.placeID}`);
                
                UI_LIST.appendChild(li)
              })
              UI_LIST.style.display = "block"
            }
            else{
              UI_LIST.style.display = "none";
            }
          })
        }
        else{
          UI_LIST.style.display = "none";
        }
      }

      const updatePropertyType = (e) => {
        if(e?.target?.value === "singleUnit"){
          setTempPropertyType(e.target.value)
          setPropertyTypeChangeModal(true);
        }
        else{
          setPropertyType("multiUnit")
        }
      }

      const setPropertyType = (e) => {
        setPropertyTypeChangeModal(false);
        setPropertySetupType(e);
        if(e == "multiUnit"){
          let tempArray = []
          propertyJson.units.forEach((unit) => {
            tempArray.push(
              <PropertyManagerSetup listName={"propertyListRegister"} unitInfo={unit}/>
            )
          })
          setPropertyDetailsList([...tempArray]);
        }
      }

  const handlePMSearchItemSelect = (address, placeID) => {
    isAddressAvailable(placeID)
    .then((resp) => {
      if(resp.length == 0){
        setSelectedAddress(address);
        setSelectedAddressPlaceID(placeID);
      }
      else{
        let e = {
          target :  {
            value : ""
          }
        }
        handleSearchChange(e)
        setSelectedAddress(null);
        setSelectedAddressPlaceID(null);
        let messageDiv = document.getElementsByClassName("message").item(0);
        FormException(null, "Property Already registered from another manager", messageDiv);
      }
    })
    .catch()
  }

  useEffect(() => {
    setPropertySetupType(propertyJson.type)
    setSelectedAddress(propertyJson.address)
    setSelectedAddressPlaceID(propertyJson.placeID)
    let tempArray = []
    propertyJson.units.forEach((unit) => {
      tempArray.push(
        <PropertyManagerSetup listName={"propertyListRegister"} unitInfo={unit}/>
      )
    })
    setPropertyDetailsList([...tempArray]);
  }, [propertyJson])
    return (
      <>
        <CustomWrapper
            width='100%'
            maxWidth={1550}
            height='600px'
            maxHeight={"100%"}
            paddingHorizontal={30}
            paddingVertical={30}
        >
            <form className='accountSetupForm fadeIn' onSubmit={handlePropertySubmit} style={{maxWidth : 900, paddingBottom : 20,overflow : 'auto', height : '100%', padding : 20}}>
                <div className='columnFormInput'>
                    {
                    selectedAddress == null && 
                    <div className='formInput'>
                        <div className='iconFormInput'>
                        <MdSearch color='#526035' size={25}/>
                        <input type='text' name="mapSearch"  onChange={handleSearchChange} placeholder='Search...' />
                        </div>
                        <ul className='searchDatalist' id='mapSearchDataList'>
                        <li>Option 1</li>
                        <li>Option 2</li>
                        <li>Option 3</li>
                        <li>Option 4</li>
                        </ul>
                    </div>
                    }
                    {
                    selectedAddress == null &&
                    <div className='message'>SampleText</div>
                    }
                    {
                    selectedAddress != null &&
                    <div className='editAddress'>
                        <div className='titleMessage' sele style={{margin : 'unset', marginLeft : 40}}>
                        {selectedAddress}
                        </div>
                        <MdOutlineEdit size={25} color='rgba(0,0,0,0.45)' onClick={editSearchAddress}/>
                    </div>
                    }
                    <div className='formInput'>
                    <label className='formRadio'>
                        <input type='radio' checked={propertySetupType == "singleUnit"} name='unitType' value={"singleUnit"} onChange={updatePropertyType} />
                        <span>Single Unit</span>
                    </label>
                    <label className='formRadio'>
                        <input type='radio'checked={propertySetupType == "multiUnit"}  name='unitType' value={"multiUnit"} onChange={updatePropertyType} />
                        <span>Multi Unit</span>
                    </label>
                    </div>
                </div>
                {
                    propertySetupType === 'singleUnit' &&
                    <PropertyManagerSetup listName={"propertyListRegister"} unitInfo={propertyJson.units[0]} />
                }
                {
                    propertySetupType === 'multiUnit' &&

                    <div className='accordian'>
                    {
                        propertyDetailsList && accordianViewContainer(propertyDetailsList)
                    }
                    </div>
                }
                {
                    propertySetupType === 'multiUnit' &&
                    <HighlighterButton
                      width='150px'
                      fontSize='12px'
                      margin='0'
                      paddingVertical='8px'
                      type='button'
                      onPressFunction={addPropertyUnit}
                    >
                    + Add Unit
                    </HighlighterButton>
                }
                <div className='pageSubmit' style={{width : 500}}>
                    <label className='accountMinButton' onClick={() => deletePropertyConfirm(true)}>
                      <MdDelete color='red' size={15}/>
                      <span style={{color : 'red'}}>Delete Property</span>
                    </label>

                    <Button
                      primary={false}
                      margin='0'
                      paddingVertical='8px'
                      type='button'
                      onPressFunction={() => pageChange("ManageProperty")}
                      >Cancel</Button>

                    <Button 
                      primary={true}
                      paddingVertical='8px'
                      margin='0'
                      type='submit'>Update</Button>
                </div>
            </form>
            {
              isDeleteCalled &&
              <PopupWrapper maxWidth={450} onClose={() => {deletePropertyConfirm(false)}}>
                <div style={{fontSize : 16, textAlign : 'center'}}>
                  Are you sure you want to delete this Property?
                </div>
                <div style={{width : 'fit-content', display : 'flex'}}>
                  <Button
                    className='minButton'
                    type='button'
                    primary={false}
                    onPressFunction={() => {deletePropertyConfirm(false)}}
                  >Cancel</Button>
                  <Button
                    className='minButton'
                    type='submit'
                    primary={true}
                    onPressFunction={delProperty}
                  >Delete</Button>
                </div>
              </PopupWrapper>
            }
            {
              propertyTypeChangeModal && 
              (
                <PopupWrapper
                  maxWidth={600}
                  onClose={() => {setPropertyTypeChangeModal(false)}}
                >
                  <div className='helpbox'>
                    <div className='helpboxHeader'>
                      <img src={require("../../../assets/help.png")} />
                      <div className='helpTitle'>
                        <p>Need help in changing property type?</p>
                        <span>Please confirm if all units associated can be deleted , so our team will process and get back to you</span>
                      </div>
                    </div>
                    <textarea name="helpInputBox" placeholder='Insert comments here.'></textarea>
                    <Button
                      className='minButton'
                      type='submit'
                      primary={true}
                      onPressFunction={openSupportTicket}
                    >Submit</Button>
                  </div>
                </PopupWrapper>
              )
            }
        </CustomWrapper>
        {isLoading && <Loader />}
      </>
    )
})
export default EditProperty