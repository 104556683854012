import React, { useEffect, useImperativeHandle, useState } from 'react'
import "./Toast.css"
import { MdNotificationsActive } from 'react-icons/md'

function Toast({backgroundColor = "#333", launchRef}) {

    const [notice, setNotice] = useState("");

    useImperativeHandle(launchRef, () => ({
        launch(message) {
            setNotice(message);
            var x = document.getElementById("toast")
            x.className = "tShow";
            setTimeout(() =>{
                x.className = x.className.replace("tShow", ""); 
            }, 4950);
        }
    }))

    return (
        <div id="toast" style={{backgroundColor : backgroundColor}}>
            <div id="toastImg">
                <MdNotificationsActive color='#FFFFFF' size={20} />
            </div>
            <div id="toastDesc">{notice}</div>
        </div>
    )
}

export default Toast