import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { fetchUserDetails } from '../../services/account.service';
import { cacheUser, isLoggedIn } from '../../utility/util';
import Header from '../../components/Header/Header';
import SideNavBar from '../../components/SideNavBar/SideNavBar';
function Maintanence({title = null}) {
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();
    
    useEffect(() => {
        const userInfo = isLoggedIn();
        if(!userInfo){
            return navigate("/login");
        }
        else{
            if(!userInfo?.data){
                fetchUserDetails(userInfo.source, userInfo.type).then((resp) => {
                    setUserData({
                        data : resp.data,
                        source : userInfo.source,
                        type : userInfo.type
                    });
                    cacheUser(resp.data)
                    if(resp?.data?.setup_completed != "Completed"){
                        return navigate("/account/setup")
                    }
                });
            }
            else{
                setUserData(userInfo);
                if(userInfo?.data?.setup_completed != "Completed"){
                    return navigate("/account/setup")
                }
            }
        }

    }, []);
    return (  
        <main style={{height : '100%', display : 'flex', flexDirection : 'column'}}>
            <Header showNotification={true}  name={userData?.data?.first_name + " " + userData?.data?.last_name} />
            <section className="mainPanel" style={{flex : 1, gap : '30px', paddingTop : 0, display : "flex", width : '100%', maxWidth : 2500}} >
                <SideNavBar userData={userData}/>
                <div className="DashContainer">
                    <div className='dashHeader'>
                        <div className="pageTitle">{title}</div>
                    </div>
                    <div style={
                            {
                                display:'flex',
                                flexDirection : 'column', 
                                height : '100%', 
                                justifyContent : 'center', 
                                alignItems : 'center'
                            }
                        }>
                    <h1>Page under maintanence</h1>
                    <p>Copyright by - Ando</p>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Maintanence