import React, { useEffect, useState } from 'react'
import "./Auth.css"
import Header from '../../components/Header/Header';
import Wrapper from '../../components/wrapper/Wrapper';
import {MdClose, MdMail, MdVisibility} from  "react-icons/md"
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { FormException } from '../../utility/exceptions';
import { generateOTP, registerWithEmailAndPassword, verifyOTP } from '../../services/auth.service';
import OtpBox from '../../components/OTPBox/OtpBox';
import { getUserDetailsFromGoogle, isLoggedIn, performLogin } from '../../utility/util';
import { useGoogleLogin } from '@react-oauth/google';
import { createAccountWithGoogle } from '../../services/account.service';
import { GoogleIcon } from '../../components/icons/Icons';
import Checkbox from '../../components/checkbox/Checkbox';
import { Loader } from '../../components/Loader/Loader';

function Register({title = "", withEmail = false}) {
  const [pageLabel, setPageLabel] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [otpCalled, setOTPCalled] = useState(false);
  const [phNumber, setPhoneNumber] = useState(0)
  const [mountDiv, setMountDiv] = useState(false);
  const [isNumberPolicyAccepted, toggleNumberPolicy] = useState(false);

  const navigate = useNavigate();

  const goBackFromOTP = () => {
    setOTPCalled(false);
  }
  useEffect(() => {
    document.title = "Sign Up | ando"
    setPageLabel(title)
    if(isLoggedIn()){
      navigate("/")
    }
  }, [title, navigate])


  const toggleInputMask = (inputId) => {
    let inputBox = document.getElementById(inputId);
    switch(inputBox.type){
      case "password" : 
        inputBox.type = "text";
      break;
      case "text" :
        inputBox.type = "password";
      break;
    }
  } 
  
  const getOTP = async (e) => {
    e.preventDefault()
    setLoading(true)
    const phoneNumber = e.target.phone_number.value;

    let messageDiv = document.getElementsByClassName("message").item(0);

    // if(phoneNumber.trim().length !== 10) return FormException(e, "Invalid Phone Number", messageDiv);

    messageDiv.style.display = "none";
    messageDiv.classList.remove("error");

    generateOTP(phoneNumber, true).then((data) => {
      setLoading(false)
      if(data.status){
        setPhoneNumber(phoneNumber);
        setMountDiv(true);
        setTimeout(() => {
          setOTPCalled(true);
        }, 300);
      }
      else{
        return FormException(e, data.message, messageDiv);
      }
    }).catch((error) => {
      setLoading(false)
      return FormException(e, error.message, messageDiv);
    })

  }

  const validateOTP = (otp, type, source) =>{
    let messageDiv = document.getElementsByClassName("message").item(0);
    setLoading(true)
    verifyOTP(otp,source,type).then((data) => {
      setLoading(false)
      if(data.status){
        messageDiv.style.display = "none";
        messageDiv.classList.remove("error");
        performLogin(source, type, data.data.token)
        if(data.data.isNewAccount){
          navigate("/account/setup")
        }
        else{
          navigate("/dashboard")
        }
      }
      else{
        return FormException(null, data.message, messageDiv);
      }
    }).catch((error) => {
      setLoading(false)
      return FormException(null, error.message, messageDiv);
    })
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setLoading(false)
      googleLoginProcess(codeResponse)
    },
    onError: (error) => {
      setLoading(false)
      console.log('Login Failed:', error)
    },
    flow : 'implicit'
  })

  const googleLoginProcess = (googleResponse) => {
    let messageDiv = document.getElementsByClassName("message").item(0);
    setLoading(true)
    getUserDetailsFromGoogle(googleResponse).then((data) => {
      let d = {
        first_name : data.given_name,
        last_name : data.family_name,
        email : data.email,
        loggedInFrom : 'google'
      }
      createAccountWithGoogle(d).then((resp) => {
        setLoading(false)
        if(resp.status){
          messageDiv.style.display = "none";
          messageDiv.classList.remove("error");
          performLogin(d.email, "email", resp.data.token)
          if(resp.data.isNewAccount){
            navigate("/account/setup")
          }
          else{
            navigate("/dashboard")
          }
        }
        else{
          return FormException(null, resp.message, messageDiv);
        }

      })
      .catch((error) => {
        setLoading(false)
        return FormException(null, error.message, messageDiv);
      })
    })
    .catch((error) => {
      setLoading(false)
      return FormException(null, "Something went Wrong", messageDiv);
    })
  }

  const emailRegister = (e) => {
    e.preventDefault();

    setLoading(true)
    let messageDiv = document.getElementsByClassName("message").item(0);
    let email = e.target.email.value;
    let password = e.target.password.value;
    let confirm_password = e.target.confirm_password.value;

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/

    if(!passwordRegex.test(password)){
      setLoading(false)
      return FormException(
          e,
          `Password criteria is not fullfilled <br>
          Password length should be more than 8 letters <br>
          Password must contain uppercase, lowercase and a number. <br>`, 
          messageDiv
        )
    }

    if(confirm_password !== password){
      setLoading(false)
      return FormException(
        e,
        "Password & Confirm Password are not same.",
        messageDiv
      )
    }
    registerWithEmailAndPassword(email, password).then((response) => {
      setLoading(false)
      if(response.status){
        messageDiv.style.display = "none";
        messageDiv.classList.remove("error");
        // performLogin(email, "email")
        if(response.data.isNewAccount){
          navigate("/login")
        }
        else{
          navigate("/dashboard")
          sessionStorage.setItem("token", response?.data?.token)
        }
      }
      else{
        return FormException(
          e,
          response.message,
          messageDiv
        )
      }
    })
    messageDiv.style.display = "none";
    messageDiv.classList.remove("error");
  }

  return (
    <main>
      <Header />
      <section className='mainPanel'>
        <span>{pageLabel}</span>
        {
          !otpCalled && !withEmail &&
          (
            <div className={(!mountDiv && !otpCalled)?"fadeIn":"fadeOut"}>
              <Wrapper>
                <MdClose onClick={() => {
                  window.location="https://dev.ando.app"
                }} color='rgba(0,0,0,0.35)' size={20} className='closePopup'/>
                <div className='signInTitleWrapper'>
                  <label>Create New Account</label>
                  <p>Sign Up using one of the below methods</p>
                </div>
      
                <form className='signInForm' onSubmit={getOTP}>
                  <div className='signInInput'>
                    <label>Mobile Number</label>
                    <input type='number' name='phone_number' id="phone_number" placeholder='+1 (123) 456 789' />
                  </div>
                  <Checkbox
                    name='numberAcceptPolicy'
                    onChange={(e) => {
                      toggleNumberPolicy(e?.target?.checked)
                    }}
                    isChecked={isNumberPolicyAccepted}
                    label='By checking this box you consent to receive text messages on number provided'
                  />
                  <Button disable={!isNumberPolicyAccepted}>Send One Time Password</Button>
                  <div className='message'>sample text</div>
                </form>
      
                <div className='hr'>
                  <span>OR</span>
                </div>
      
                <Button primary={false} onPressFunction={googleLogin}>
                <GoogleIcon size={18} />&nbsp; Sign Up with Google
                </Button>
      
                <div className='hr'>
                  <span>OR</span>
                </div>
                
                <Link to="/register/email">
                  <Button primary={false}>
                    <MdMail color='#526035' size={18} />&nbsp; Sign Up with Email & Password
                  </Button>
                </Link>
                <p className='tncMessage' onClick={() => {window.open("/tnc", "_blank", 'rel=noopener noreferrer')}}>By Signing In to ando, you agree to our Privacy Policies & Terms of Services</p>
      
                <div className="signLinkContainer">Already Registered? <Link to={"/login"} className='loginLinks'>Sign In Here</Link> </div>
              </Wrapper>
            </div>
          )
        }
        {
          !otpCalled && withEmail &&
          (
            <div className={(!mountDiv && !otpCalled)?"fadeIn":"fadeOut"}>
              <Wrapper>
                <Link to="/register">
                  <MdClose color='rgba(0,0,0,0.35)' size={20} className='closePopup'/>
                </Link>
                <div className='signInTitleWrapper'>
                  <label>Create New Account</label>
                  <p>Enter your email address and create password</p>
                </div>
      
                <form className='signInForm' onSubmit={emailRegister}>
                  <div className='signInInput'>
                    <label>Email</label>
                    <input type='email' name='email' id="email" placeholder='example@dot.com' required/>
                  </div>
                  <div className='signInInput'>
                    <label>Password</label>
                    <input type='password' name='password' id="password" placeholder='Password' required/>
                    <MdVisibility 
                      className='maskInputContainer'
                      onClick={toggleInputMask.bind(this, "password")}
                      color='rgba(0,0,0,0.25)' 
                      size={18}/>
                  </div>
                  <div className='signInInput'>
                    <label>Confirm Password</label>
                    <input type='password' name='confirm_password' id="confirm_password" placeholder='Password' required />
                    <MdVisibility 
                      className='maskInputContainer'
                      onClick={toggleInputMask.bind(this, "confirm_password")}
                      color='rgba(0,0,0,0.25)' 
                      size={18}/>
                  </div>
                  <Button>Create New Account</Button>
                  <div className='message' style={{display : 'block'}}>
                  <b>Please follow the below password criteria</b><br />
                  Password length should be more than 8 letters <br />
                  Password must contain uppercase, lowercase and a number. <br />
                  </div>
                </form>
      
                <div className='hr'></div>
      
                <p className='tncMessage' onClick={() => {window.open("/tnc", "_blank", 'rel=noopener noreferrer')}}>By Signing In to ando, you agree to our Privacy Policies & Terms of Services</p>
      
                <div className="signLinkContainer">Already a User? <Link to={"/login"} className='loginLinks'>Sign In Here</Link> </div>
              </Wrapper>
            </div>
          )
        }

        {
          otpCalled &&
          (
            <div className={mountDiv && otpCalled?"fadeIn":"fadeOut"}>
              <Wrapper>
                <MdClose color='rgba(0,0,0,0.35)' size={20} className='closePopup' onClick={goBackFromOTP}/>
                <div className='signInTitleWrapper'>
                  <label>OTP Verification</label>
                  <p>Enter the  six digit passcode sent to <b>{phNumber}</b></p>
                </div>
                <div className='signInForm'>
                  <OtpBox 
                    length={6}
                    handleValidation={validateOTP}
                    source={phNumber}
                    isNew={true}
                    type={withEmail?"email" : "phone"}
                  />
                </div>
              </Wrapper>
            </div>
          )
        }
      </section>
      {isLoading && <Loader />}
    </main>
  )
}

export default Register