import React from 'react'
import "./Wrapper.css"
import CustomWrapper from './CustomWrapper'
import { MdClose } from 'react-icons/md'

function PopupWrapper({zIndex = 1, maxWidth = 850, children, height='auto',overflowY="hidden", onClose = null}) {
  return (
    <div className='popupContainer' style={{zIndex : zIndex}}>
        <CustomWrapper
            width='fit-content'
            maxWidth={maxWidth}
            paddingHorizontal={30}
            paddingVertical={30}
            height={height}
            overflowY={overflowY}
          
        >
            <MdClose onClick={onClose} color='rgba(0,0,0,0.35)' size={20} className='closePopup'/>
            {children}
        </CustomWrapper>
    </div>
  )
}

export default PopupWrapper