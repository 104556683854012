import React, { useEffect, useState } from 'react'
import "./NotificationBar.css"
import { readNotice } from '../../services/account.service';
function Notice({currentReadStatus = false, title, description, id, date = new Date()}) {
    const [isNoticeOpen, setNoticeStatus] = useState(false);
    const [readStatus, markAsRead] = useState(false);
    useEffect(() => {
        markAsRead(currentReadStatus);
    },[])
    const changeNoticeStatus = (status) => {
        setNoticeStatus(status);
        readNotice(id);
    }
    return (
        <div className="notification-message" key={id}>
            {
                !readStatus &&
                <div className='readMark'></div>
            }
            <div className='notice'>
                <div className='notificationHeader'>
                    <div className="messsage">{title}</div>
                    <div className="time">
                        {
                            `${date?.toLocaleString("default", {month : "short"})} ${date?.getDate()}, ${date.getFullYear()}`
                        }
                    </div>
                </div>
                <div className='notificationMain'>
                    <div 
                        className='notificationDescription' 
                        style={
                            isNoticeOpen ? {whiteSpace : "normal"} : {whiteSpace : "nowrap"}
                        }
                    >{description}</div>
                    {
                        isNoticeOpen && 
                        <div onClick={() => {changeNoticeStatus(false)}} className='viewNotification'>View Less</div>
                    }
                    {
                        !isNoticeOpen && 
                        <div onClick={() => {changeNoticeStatus(true);markAsRead(true)}} className='viewNotification'>View More</div>
                    }
                </div>
         
            </div>
        </div>
    )
}

export default Notice